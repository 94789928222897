<script>
import { mapMutations, mapState } from 'vuex';
import * as remortgageMutations from '@/store/remortgage/mutations';
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';
import QuickEditPerson from '@/components/forms/saleClientQuestionnaire/QuickEditPersonQuestionnaire';
import patchRemortgage from '@/api/remortgages/patchRemortgage';
import Loader from '@/components/Loader';

export default {
  name: 'ReviewPage',
  components: { AddressLookup, QuickEditPerson, Loader },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editingAddress: false,
      checked: false,
      saving: false,
    };
  },
  computed: {
    ...mapState({
      s_clientIds: (state) => state.remortgage.clientIds,
      s_persons: (state) => state.persons.clients,
      s_clientDetailsUpdated: (state) => state.persons.clientDetailsUpdated,
      s_remortgageId: (state) => state.remortgage.remortgageId,
      s_remortgageAddress: (state) => state.remortgage.address,
      s_remortgageAddressUpdated: (state) => state.remortgage.remortgageAddressUpdated,
      s_standaloneToe: (state) => state.remortgage.standaloneToe,
    }),
    clientDetailsUpdated: {
      get() {
        return this.s_clientDetailsUpdated;
      },
    },
    remortgageAddressUpdated: {
      get() {
        return this.s_remortgageAddressUpdated;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_REMORTGAGE_ADDRESS_UPDATED}`](
          value,
        );
      },
    },
    remortgageAddress: {
      get() {
        return this.s_remortgageAddress;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDRESS}`](value);
      },
    },
    getPersons: {
      get() {
        return this.s_persons;
      },
    },
  },
  methods: {
    ...mapMutations([
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDRESS}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_REMORTGAGE_ADDRESS_UPDATED}`,
    ]),
    toggleEditAddress(value) {
      this.editingAddress = value;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async validateConfirmation() {
      return await this.$refs.checkboxObserver.validate();
    },
    async updateAddress() {
      const isValid = await this.$refs.remortgageAddressObserver.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      const preparedRemortgageData = {
        id: this.s_remortgageId,
        address: this.s_remortgageAddress,
      };

      await patchRemortgage({
        ...preparedRemortgageData,
        ...{ id: this.s_remortgageId },
      });

      this.remortgageAddressUpdated = true;

      this.editingAddress = false;
      this.saving = false;

      return true;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col my-2">
      <h2 class="col-md-12">Please check the following details are correct before submitting</h2>

      <div class="col-md-12">
        <div class="section-note">
          <b>
            These details will be used on your legal documents. Please verify the Property address and ensure that ALL
            persons registered on the Property Title are present with FULL NAMES (including middle names) as they would
            appear on your Passport or Driving Licence:
          </b>
        </div>
      </div>

      <div class="border-top my-3"></div>

      <!-- View/Edit Persons -->
      <div class="col-md-12" :key="clientDetailsUpdated">
        <div v-for="(item, index) in getPersons" :key="index">
          <h1 class="my-2">Person {{ index + 1 }} {{ item.firstName }} Details:</h1>
          <quick-edit-person :value="item" :personIndex="index"> </quick-edit-person>
        </div>
      </div>
      <!-- View/Edit Persons End -->

      <div class="border-top my-3"></div>

      <!-- View/Edit Remortgage Address -->
      <loader v-if="saving">Saving Address...</loader>
      <div v-else>
        <ValidationObserver ref="remortgageAddressObserver">
          <h1 class="col-md-12 my-2">{{ s_standaloneToe ? '' : 'Remortgage ' }}Property Address:</h1>

          <div class="col-md-12">
            <address-lookup
              v-model="remortgageAddress"
              :key="this.remortgageAddressUpdated"
              :addResidency="false"
              :requiresValidation="editingAddress"
              :displayOnly="!editingAddress"
            ></address-lookup>

            <div v-if="!editingAddress" class="row pb-2">
              <div class="col-lg-10"></div>

              <div class="col col-lg-2">
                <button class="btn btn-edit bg-primary btn-block" @click="toggleEditAddress(true)"></button>
              </div>
            </div>

            <div v-else class="row pb-2">
              <div class="col-lg-8"></div>

              <div class="col col-lg-2">
                <button class="btn btn-save btn-block" @click="updateAddress"></button>
              </div>

              <div class="col col-lg-2">
                <button class="btn btn-cancel btn-block" @click="toggleEditAddress(false)"></button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <!-- View/Edit Remortgage Address End -->

      <div class="border-top my-3"></div>

      <!-- Checkbox Confirmation -->
      <div>
        <div class="row no-gutters">
          <div class="form-group form-check my-1">
            <div class="col-md-12">
              <ValidationObserver ref="checkboxObserver">
                <ValidationProvider name="confirmation" rules="confirmation" v-slot="{ errors }">
                  <span class="form-text text-danger">{{ errors[0] }}</span>
                  <b-form-checkbox type="checkbox" v-model="checked" id="confirmation" class="form-check-input">
                    <h5>
                      I confirm that <strong>all person(s) details</strong> listed above and
                      <strong>{{ s_standaloneToe ? '' : 'remortgage ' }}property address</strong> are correct.
                    </h5>
                  </b-form-checkbox>
                </ValidationProvider>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
      <!-- Checkbox Confirmation End-->
    </div>
  </div>
</template>

<style scoped>
/deep/ .form-control:disabled {
  color: black !important;
}
</style>
