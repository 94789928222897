import { mapState } from 'vuex';

const noMatchingLenderMessage = "No matches, please select 'Other' and specify the name manually."; // eslint-disable-line quotes

export default {
  data() {
    return {
      noMatchingLenderMessage,
    };
  },
  computed: {
    ...mapState({
      s_lenders: (state) => state.lenders.lenders,
    }),
    // Get the options for a lender dropdown list as an array.
    lenders: {
      get() {
        return JSON.parse(JSON.stringify(this.s_lenders));
      },
    },
    // Get the 'Other' lender option value.
    otherLenderOption: {
      get() {
        return this.lenders.find((l) => {
          return l.id === 0;
        });
      },
    },
  },
  methods: {
    getLenderNameOptionFromName(name) {
      if (name === null || name === undefined) {
        return null;
      }

      let selectedOption;

      if (name !== '') {
        selectedOption = this.lenders.find((l) => {
          return l.label === name;
        });
      }

      if (selectedOption === undefined) {
        return this.otherLenderOption;
      }

      return selectedOption;
    },
    getLenderNameFromOption(value) {
      let lenderName;
      if (!value || value.id === null || value.id === undefined) {
        lenderName = null;
      } else if (value.id === 0) {
        lenderName = '';
      } else {
        lenderName = value.label;
      }
      return lenderName;
    },
  },
};
