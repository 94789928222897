<script>
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';
import { mapMutations, mapState } from 'vuex';
import * as remortgageMutations from '@/store/remortgage/mutations';
import patchRemortgage from '@/api/remortgages/patchRemortgage';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import DatePicker from '@/components/DatePicker';
import CurrencyInput from '@/components/forms/inputGroups/CurrencyInput';
import LenderOptions from '@/mixins/LenderOptions';
import { ERC_EXISTS_OPTION_YES_KNOWN, ERC_EXISTS_OPTION_YES_UNKNOWN, ERC_EXISTS_OPTION_NO } from '@/values/ercExists';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import aboutYourRemortgage from '@/functions/supportingDocuments/aboutYourRemortgage';
import Modal from '../../Modal.vue';

const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrUnsure = {
  YNU_YES: 'Yes',
  YNU_NO: 'No',
  YNU_UNSURE: 'Unsure',
};

const isThereAnErcOptions = {
  [ERC_EXISTS_OPTION_YES_KNOWN]: 'Yes, and I know the ERC end date.',
  [ERC_EXISTS_OPTION_YES_UNKNOWN]: "Yes, but I don't know the ERC end date.", // eslint-disable-line quotes
  [ERC_EXISTS_OPTION_NO]: 'No, there is no ERC.',
};

const tenureTypeOptions = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'Freehold / Feudal', text: 'Freehold' },
  { value: 'Leasehold', text: 'Leasehold' },
];

export default {
  name: 'AboutYourRemortgage',
  mixins: [LenderOptions],
  components: {
    AddressLookup,
    DatePicker,
    CurrencyInput,
    Modal,
    InlineFileUploader,
    SimpleQuestion,
  },
  data() {
    return {
      yesOrNo,
      yesOrNoOrUnsure,
      enclosedOrToFollow,
      tenureTypeOptions,
      aboutYourRemortgage,
      isThereAnErcOptions,
      showErcConfirmation: false,
      showSecondErcConfirmation: false,
      ercConfirmationCurrentLenderOpened: false,
      ercConfirmationAdditionalLenderOpened: false,
      additionalLenderStatus: false,
      showCurrentLenderSection: true,
      showAdditionalLenderSection: true,
      showNewLenderSection: true,
      showAdditionalBorrowing: false,
      tabTitle: 'Mortgage Details',
      addressTitle: 'Remortgage Address',
      targetHcaText: 'Have you contacted Target HCA for consent to the re-mortgage?',
    };
  },
  computed: {
    ...mapState({
      s_additionalLenderAccountNumber: (state) => state.remortgage.additionalLenderAccountNumber,
      s_additionalLenderAmountOwed: (state) => state.remortgage.additionalLenderAmountOwed,
      s_additionalLenderName: (state) => state.remortgage.additionalLenderName,
      s_additionalLenderKeep: (state) => state.remortgage.additionalLenderKeep,
      s_additionalLenderHelpToBuyCharge: (state) => state.remortgage.additionalLenderHelpToBuyCharge,
      s_additionalLenderHelpToBuyChargePayingOff: (state) => state.remortgage.additionalLenderHelpToBuyChargePayingOff,
      s_additionalLenderTargetHcaToPayOff: (state) => state.remortgage.additionalLenderTargetHcaToPayOff,
      s_additionalLenderTargetHcaToRemortgage: (state) => state.remortgage.additionalLenderTargetHcaToRemortgage,
      s_additionalLenderEarlyRepaymentCharge: (state) => state.remortgage.additionalLenderEarlyRepaymentCharge,
      s_additionalLenderEarlyRepaymentChargeEndDate: (state) =>
        state.remortgage.additionalLenderEarlyRepaymentChargeEndDate,
      s_additionalLenderEarlyRepaymentChargeExists: (state) =>
        state.remortgage.additionalLenderEarlyRepaymentChargeExists,
      s_additionalLenderEarlyRepaymentChargePostponeCompletion: (state) =>
        state.remortgage.additionalLenderEarlyRepaymentChargePostponeCompletion,
      s_additionalLenderEarlyRepaymentChargeUnknown: (state) =>
        state.remortgage.additionalLenderEarlyRepaymentChargeUnknown,
      s_currentLenderAccountNumber: (state) => state.remortgage.currentLenderAccountNumber,
      s_currentLenderAmountOwed: (state) => state.remortgage.currentLenderAmountOwed,
      s_currentLenderName: (state) => state.remortgage.currentLenderName,
      s_currentLenderMakingOverpayment: (state) => state.remortgage.currentLenderMakingOverpayment,
      s_currentLenderOverpaymentAmount: (state) => state.remortgage.currentLenderOverpaymentAmount,
      s_currentLenderBankStatementDocuments: (state) => state.remortgage.currentLenderBankStatementDocuments,
      s_currentLenderAdditionalBorrowing: (state) => state.remortgage.currentLenderAdditionalBorrowing,
      s_currentLenderAdditionalBorrowingAmount: (state) => state.remortgage.currentLenderAdditionalBorrowingAmount,
      s_earlyRepaymentCharge: (state) => state.remortgage.earlyRepaymentCharge,
      s_earlyRepaymentChargeEndDate: (state) => state.remortgage.earlyRepaymentChargeEndDate,
      s_earlyRepaymentChargeExists: (state) => state.remortgage.earlyRepaymentChargeExists,
      s_earlyRepaymentChargePostponeCompletion: (state) => state.remortgage.earlyRepaymentChargePostponeCompletion,
      s_earlyRepaymentChargeUnknown: (state) => state.remortgage.earlyRepaymentChargeUnknown,
      s_leaseHoldAdditionalInfo: (state) => state.remortgage.leaseholdAdditionalInfo,
      s_newLenderName: (state) => state.remortgage.newLenderName,
      s_newLenderAmountOwed: (state) => state.remortgage.newLenderAmountOwed,
      s_passStatementsToBroker: (state) => state.remortgage.passStatementsToBroker,
      s_remortgageId: (state) => state.remortgage.remortgageId,
      s_remortgageAddress: (state) => state.remortgage.address,
      s_sortReferId: (state) => state.sortReferId,
      s_remortgageAddressUpdated: (state) => state.remortgage.remortgageAddressUpdated,
      s_tenureType: (state) => state.remortgage.tenureType,
      s_standaloneToe: (state) => state.remortgage.standaloneToe,
      s_existingMortgage: (state) => state.remortgage.existingMortgage,
    }),
    remortgageAddressUpdated: {
      get() {
        return this.s_remortgageAddressUpdated;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_REMORTGAGE_ADDRESS_UPDATED}`](
          value,
        );
      },
    },
    remortgageAddress: {
      get() {
        return this.s_remortgageAddress;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDRESS}`](value);
      },
    },
    leaseholdAdditionalInformation: {
      get() {
        return this.s_leaseHoldAdditionalInfo;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_LEASEHOLD_ADDITIONAL_INFO}`](
          value,
        );
      },
    },
    currentLenderName: {
      get() {
        return this.s_currentLenderName;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_NAME}`](
          value,
        );
      },
    },
    currentLenderNameOption: {
      get() {
        return this.getLenderNameOptionFromName(this.s_currentLenderName);
      },
    },
    existingAccountNumber: {
      get() {
        return this.s_currentLenderAccountNumber;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_ACCOUNT_NUMBER}`
        ](value);
      },
    },
    currentLenderAmountOwed: {
      get() {
        return this.s_currentLenderAmountOwed;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_AMOUNT_OWED}`
        ](value);
      },
    },
    makingOverpayment: {
      get() {
        return this.s_currentLenderMakingOverpayment;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_MAKING_OVERPAYMENT}`
        ](value);
      },
    },
    currentLenderOverpaymentAmount: {
      get() {
        return this.s_currentLenderOverpaymentAmount;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_OVERPAYMENT_AMOUNT}`
        ](value);
      },
    },
    currentLenderBankStatementDocuments: {
      get() {
        return {
          answer: this.s_currentLenderBankStatementDocuments,
        };
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_BANK_STATEMENT_DOCUMENTS}`
        ](value.answer);
      },
    },
    currentLenderAdditionalBorrowing: {
      get() {
        return this.s_currentLenderAdditionalBorrowing;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING}`
        ](value);
      },
    },
    currentLenderAdditionalBorrowingAmount: {
      get() {
        return this.s_currentLenderAdditionalBorrowingAmount;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING_AMOUNT}`
        ](value);
      },
    },
    additionalLenderName: {
      get() {
        return this.s_additionalLenderName;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_NAME}`](
          value,
        );
      },
    },
    additionalLenderNameOption: {
      get() {
        return this.getLenderNameOptionFromName(this.s_additionalLenderName);
      },
    },
    additionalLenderAccountNumber: {
      get() {
        return this.s_additionalLenderAccountNumber;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_ACCOUNT_NUMBER}`
        ](value);
      },
    },
    additionalLenderAmountOwed: {
      get() {
        return this.s_additionalLenderAmountOwed;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_AMOUNT_OWED}`
        ](value);
      },
    },
    additionalLenderKeep: {
      get() {
        return this.s_additionalLenderKeep;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_KEEP}`](
          value,
        );
      },
    },
    additionalLenderHelpToBuyCharge: {
      get() {
        return {
          answer: this.s_additionalLenderHelpToBuyCharge,
        };
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE}`
        ](value.answer);
      },
    },
    additionalLenderHelpToBuyChargePayingOff: {
      get() {
        return {
          answer: this.s_additionalLenderHelpToBuyChargePayingOff,
        };
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE_PAYING_OFF}`
        ](value.answer);
      },
    },
    additionalLenderTargetHcaToPayOff: {
      get() {
        return {
          answer: this.s_additionalLenderTargetHcaToPayOff,
        };
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_PAY_OFF}`
        ](value.answer);
      },
    },
    additionalLenderTargetHcaToRemortgage: {
      get() {
        return {
          answer: this.s_additionalLenderTargetHcaToRemortgage,
        };
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_REMORTGAGE}`
        ](value.answer);
      },
    },
    additionalLenderKeepRequired: {
      get() {
        return Boolean(
          this.s_additionalLenderAmountOwed || this.s_additionalLenderAccountNumber || this.s_additionalLenderName,
        );
      },
    },
    additionalLenderEarlyRepaymentCharge: {
      get() {
        return this.s_additionalLenderEarlyRepaymentCharge;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE}`
        ](value);
      },
    },
    additionalLenderEarlyRepaymentChargeEndDate: {
      get() {
        return this.s_additionalLenderEarlyRepaymentChargeEndDate;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_END_DATE}`
        ](value);
      },
    },
    additionalLenderEarlyRepaymentChargeExists: {
      get() {
        return this.s_additionalLenderEarlyRepaymentChargeExists;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_EXISTS}`
        ](value);
      },
    },
    additionalLenderEarlyRepaymentChargePostponeCompletion: {
      get() {
        return this.s_additionalLenderEarlyRepaymentChargePostponeCompletion;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION}`
        ](value);
      },
    },
    additionalLenderEarlyRepaymentChargeUnknown: {
      get() {
        return this.s_additionalLenderEarlyRepaymentChargeUnknown;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_UNKNOWN}`
        ](value);
        // If the early repayment charge is unknown, also reset the early repayment charge value
        if (value === true) {
          this[
            `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE}`
          ](null);
        }
      },
    },
    newLenderName: {
      get() {
        return this.s_newLenderName;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_NEW_LENDER_NAME}`](value);
      },
    },
    newLenderNameOption: {
      get() {
        return this.getLenderNameOptionFromName(this.s_newLenderName);
      },
    },
    newLenderAmountOwed: {
      get() {
        return this.s_newLenderAmountOwed;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_NEW_LENDER_AMOUNT_OWED}`](
          value,
        );
      },
    },
    earlyRepaymentPenalty: {
      get() {
        return this.s_earlyRepaymentCharge;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE}`](
          value,
        );
      },
    },
    earlyRepaymentPenaltyEndDate: {
      get() {
        return this.s_earlyRepaymentChargeEndDate;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_END_DATE}`
        ](value);
      },
    },
    earlyRepaymentPenaltyExists: {
      get() {
        return this.s_earlyRepaymentChargeExists;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_EXISTS}`
        ](value);
      },
    },
    postponeCompletion: {
      get() {
        return this.s_earlyRepaymentChargePostponeCompletion;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION}`
        ](value);
      },
    },
    earlyRepaymentChargeUnknown: {
      get() {
        return this.s_earlyRepaymentChargeUnknown;
      },
      set(value) {
        this[
          `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_UNKNOWN}`
        ](value);
        // If the early repayment charge is unknown, also reset the early repayment charge value
        if (value === true) {
          this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE}`](
            null,
          );
        }
      },
    },
    passStatementsToBroker: {
      get() {
        return this.s_passStatementsToBroker;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_PASS_STATEMENTS_TO_BROKER}`](
          value,
        );
      },
    },
    showAdditionalLenderStatus: {
      get() {
        return typeof this.additionalLenderStatus === 'boolean'
          ? this.additionalLenderStatus
          : this.additionalLenderStatus === 'true';
      },
    },
    tenureType: {
      get() {
        return this.s_tenureType;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_TENURE_TYPE}`](value);
      },
    },
    showAdditionalLenderFields: {
      get() {
        return this.s_additionalLenderName != null && this.s_additionalLenderName != '';
      },
    },
    showErcQuestions: {
      get() {
        return (
          this.s_earlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_KNOWN ||
          this.s_earlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_UNKNOWN
        );
      },
    },
    additionalLenderShowErcQuestions: {
      get() {
        return (
          this.s_additionalLenderEarlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_KNOWN ||
          this.s_additionalLenderEarlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_UNKNOWN
        );
      },
    },
    showErcEndDateQuestion: {
      get() {
        return this.s_earlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_KNOWN;
      },
    },
    additionalLenderShowErcEndDateQuestion: {
      get() {
        return this.s_additionalLenderEarlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_KNOWN;
      },
    },
    showCurrentLenderNameFreeTextInput: {
      get() {
        return this.currentLenderNameOption && this.currentLenderNameOption.id === 0;
      },
    },
    showAdditionalLenderNameFreeTextInput: {
      get() {
        return this.additionalLenderNameOption && this.additionalLenderNameOption.id === 0;
      },
    },
    showNewLenderNameFreeTextInput: {
      get() {
        return this.newLenderNameOption && this.newLenderNameOption.id === 0;
      },
    },
    showOverpaymentAmount: function () {
      return typeof this.s_currentLenderMakingOverpayment === 'boolean'
        ? this.s_currentLenderMakingOverpayment
        : this.s_currentLenderMakingOverpayment === 'true';
    },
    showAdditionalBorrowingAmount: function () {
      return typeof this.s_currentLenderAdditionalBorrowing === 'boolean'
        ? this.s_currentLenderAdditionalBorrowing
        : this.s_currentLenderAdditionalBorrowing === 'true';
    },
  },
  watch: {
    // We want to check if the end user has selected either yes or no for the additional lender question
    // If the user has selected no ensure that we null any data entered into the related fields before posting to nest
    additionalLenderStatus() {
      if (this.additionalLenderStatus == false)
        // Setting the additionalLenderName to null will result in whole additional lender object to null in the post data body
        // However subsequest fields such as additional lender account number and money owed will still have the text data in the form field only so the end user doesn't have to input this info again if required
        this.additionalLenderName = null;
      // Setting the additionalLenderEarlyRepaymentChargeExists to null will result in whole erc additional lender object to null in the post data body
      this.additionalLenderEarlyRepaymentChargeExists = null;
      this.setAdditionalLenderName();
    },
  },
  methods: {
    ...mapMutations([
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDRESS}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_INFO}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_NAME}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_ACCOUNT_NUMBER}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_AMOUNT_OWED}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_NAME}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_ACCOUNT_NUMBER}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_AMOUNT_OWED}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_KEEP}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE_PAYING_OFF}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_PAY_OFF}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_REMORTGAGE}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_END_DATE}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_EXISTS}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_UNKNOWN}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_MAKING_OVERPAYMENT}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_OVERPAYMENT_AMOUNT}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_BANK_STATEMENT_DOCUMENTS}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING_AMOUNT}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_LEASEHOLD_ADDITIONAL_INFO}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_NEW_LENDER_NAME}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_NEW_LENDER_AMOUNT_OWED}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_END_DATE}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_EXISTS}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_UNKNOWN}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_PASS_STATEMENTS_TO_BROKER}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_REMORTGAGE_ADDRESS_UPDATED}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_TENURE_TYPE}`,
    ]),
    setCurrentLenderName(value) {
      this.currentLenderName = this.getLenderNameFromOption(value);
    },
    setAdditionalLenderName(value) {
      this.additionalLenderName = this.getLenderNameFromOption(value);
    },
    setNewLenderName(value) {
      this.newLenderName = this.getLenderNameFromOption(value);
    },
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true],.v-select.is-invalid input').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      const preparedRemortgageData = {
        id: this.s_remortgageId,
        address: this.s_remortgageAddress,
        leaseholdAdditionalInfo: this.s_leaseHoldAdditionalInfo,
        additionalLenderAccountNumber: this.s_additionalLenderAccountNumber,
        additionalLenderAmountOwed: this.s_additionalLenderAmountOwed,
        additionalLenderKeep: this.s_additionalLenderKeep,
        additionalLenderName: this.s_additionalLenderName,
        additionalLenderHelpToBuyCharge: this.s_additionalLenderHelpToBuyCharge,
        additionalLenderHelpToBuyChargePayingOff: this.s_additionalLenderHelpToBuyChargePayingOff,
        additionalLenderTargetHcaToPayOff: this.s_additionalLenderTargetHcaToPayOff,
        additionalLenderTargetHcaToRemortgage: this.s_additionalLenderTargetHcaToRemortgage,
        additionalLenderEarlyRepaymentCharge: this.s_additionalLenderEarlyRepaymentCharge,
        additionalLenderEarlyRepaymentChargeEndDate: this.s_additionalLenderEarlyRepaymentChargeEndDate,
        additionalLenderEarlyRepaymentChargeExists: this.s_additionalLenderEarlyRepaymentChargeExists,
        additionalLenderEarlyRepaymentChargePostponeCompletion:
          this.s_additionalLenderEarlyRepaymentChargePostponeCompletion,
        additionalLenderEarlyRepaymentChargeUnknown: this.s_additionalLenderEarlyRepaymentChargeUnknown,
        currentLenderAccountNumber: this.s_currentLenderAccountNumber,
        currentLenderAmountOwed: this.s_currentLenderAmountOwed,
        currentLenderName: this.s_currentLenderName,
        currentLenderMakingOverpayment: this.s_currentLenderMakingOverpayment,
        currentLenderOverpaymentAmount: this.s_currentLenderOverpaymentAmount,
        currentLenderBankStatementDocuments: this.s_currentLenderBankStatementDocuments,
        currentLenderAdditionalBorrowing: this.s_currentLenderAdditionalBorrowing,
        currentLenderAdditionalBorrowingAmount: this.s_currentLenderAdditionalBorrowingAmount,
        earlyRepaymentCharge: this.s_earlyRepaymentCharge,
        earlyRepaymentChargeEndDate: this.s_earlyRepaymentChargeEndDate,
        earlyRepaymentChargeExists: this.s_earlyRepaymentChargeExists,
        earlyRepaymentChargePostponeCompletion: this.s_earlyRepaymentChargePostponeCompletion,
        earlyRepaymentChargeUnknown: this.s_earlyRepaymentChargeUnknown,
        newLenderAmountOwed: this.s_newLenderAmountOwed,
        newLenderName: this.s_newLenderName,
        passStatementsToBroker: this.s_passStatementsToBroker,
        sortReferId: this.s_sortReferId,
        tenureType: this.s_tenureType,
      };

      await patchRemortgage({
        ...preparedRemortgageData,
        ...{ id: this.s_remortgageId },
      });

      // force rerender review page
      this.remortgageAddressUpdated = true;

      this.saving = false;
      return true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    confirmPostponeCompletion() {
      // We receive this as a string from the store, so we need to treat it as such
      if (this.postponeCompletion === 'false') {
        this.showErcConfirmation = true;
        this.ercConfirmationCurrentLenderOpened = true;
      }
    },
    confirmPostponeCompletionAdditionalLender() {
      // We receive this as a string from the store, so we need to treat it as such
      if (this.additionalLenderEarlyRepaymentChargePostponeCompletion === 'false') {
        this.showErcConfirmation = true;
        this.ercConfirmationAdditionalLenderOpened = true;
      }
    },
    firstErcWarningHandler(acceptEarlyRepaymentCharge) {
      if (acceptEarlyRepaymentCharge) {
        this.showSecondErcConfirmation = true;
      } else {
        this.setErcForOpenedLender();
      }
      this.showErcConfirmation = false;
    },
    secondErcWarningHandler(acceptEarlyRepaymentCharge) {
      if (!acceptEarlyRepaymentCharge) {
        this.setErcForOpenedLender();
      }
      this.showSecondErcConfirmation = false;
    },
    setErcForOpenedLender() {
      // twig to use one modal logic for current or additional lender
      if (this.ercConfirmationCurrentLenderOpened) {
        this.postponeCompletion = 'true';
        this.ercConfirmationCurrentLenderOpened = 'false';
      }

      if (this.ercConfirmationAdditionalLenderOpened) {
        this.additionalLenderEarlyRepaymentChargePostponeCompletion = 'true';
        this.ercConfirmationAdditionalLenderOpened = 'false';
      }
    },
    loadPageSettings() {
      const stdToe = this.s_standaloneToe;
      const mortgage = this.s_existingMortgage;

      if (stdToe) {
        this.tabTitle = 'Property Details';
      }

      // If the ToE property does not have a mortgage
      if (stdToe && !mortgage) {
        // should refer to "property"/"property address"
        this.addressTitle = 'Property Address';
        // Hide current/additional/new lender sections
        this.showCurrentLenderSection = false;
        this.showAdditionalLenderSection = false;
        this.showNewLenderSection = false;
      }

      // If the ToE property does not have a mortgage
      if (stdToe && mortgage) {
        // should refer to "property"/"property address"
        this.addressTitle = 'Property Address';
        this.targetHcaText = 'Have you contacted Target HCA for consent to the transfer of equity?';
        this.showCurrentLenderSection = true;
        this.showAdditionalLenderSection = true;
        this.showNewLenderSection = false;
        this.showAdditionalBorrowing = true;
      }
    },
  },
  mounted() {
    this.loadPageSettings();
  },
};
</script>

<template>
  <validation-observer ref="observer">
    <div>
      <h2>{{ tabTitle }}</h2>

      <div class="row pb-2">
        <div class="col-md-12">
          <div class="section-title">
            <h3>General</h3>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Tenure Type: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="tenure type" rules="required" v-slot="validationContext">
            <b-form-select
              v-model="tenureType"
              :options="tenureTypeOptions"
              id="tenure-type"
              class="form-control bg-white"
              :state="getValidationState(validationContext)"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="tenure-type-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> {{ addressTitle }} </label>
          </h5>
        </div>
      </div>

      <address-lookup
        :key="remortgageAddressUpdated"
        :addResidency="false"
        v-model="remortgageAddress"
      ></address-lookup>

      <div class="row pb-2">
        <div class="col-md-12">
          <div class="section-title">
            <h3>Additional Information</h3>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <div class="section-note">
            <p>
              If the property is Leasehold, please provide the freeholder and/or management company full contact
              information as we will require evidence from them that there are no arrears plus a copy of the current
              buildings insurance. We will need to inform them on completion of the change of your lender and they may
              charge an administration fee for this.
            </p>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <h5>
            <label for="leasehold-additional-information">Additional information:</label>
          </h5>

          <ValidationProvider name="additional information" :rules="{ required: false }" v-slot="validationContext">
            <b-form-textarea
              v-model="leaseholdAdditionalInformation"
              id="leasehold-additional-information"
              rows="4"
              :state="getValidationState(validationContext)"
            ></b-form-textarea>
            <b-form-invalid-feedback
              v-if="validationContext.errors[0]"
              data-error-name="leasehold-additional-information-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <!-- Current Lender -->
      <div v-if="showCurrentLenderSection">
        <div class="row pb-2">
          <div class="col-md-12">
            <div class="section-title">
              <h3>Current Lender</h3>
            </div>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Current Lender </label>
            </h5>
          </div>
          <div class="col-md-9">
            <ValidationProvider name="current lender name" rules="required" v-slot="validationContext">
              <v-select
                :value="currentLenderNameOption"
                @input="setCurrentLenderName"
                :options="lenders"
                id="current-lender-name"
                class="bg-white"
                v-bind:class="{
                  'is-valid': !validationContext.errors.length,
                  'is-invalid': validationContext.errors.length,
                }"
                :state="getValidationState(validationContext)"
                ><span slot="no-options">{{ noMatchingLenderMessage }}</span></v-select
              >
              <div
                v-if="validationContext.errors"
                v-bind:class="{ 'invalid-feedback d-block': validationContext.errors }"
                data-error-name="current-lender-name-select-error"
              >
                {{ validationContext.errors[0] }}
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div v-if="showCurrentLenderNameFreeTextInput">
          <div class="row pb-2">
            <div class="col-md-3">
              <h5>
                <label class="mandatory"> Current Lender (Other) </label>
              </h5>
            </div>
            <div class="col-md-9">
              <ValidationProvider name="current lender name" rules="required" v-slot="validationContext">
                <b-form-input
                  v-model="currentLenderName"
                  name="currentLenderName"
                  debounce="500"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback data-error-name="current-lender-name-other-error"
                  >{{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Existing account number: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="existing account number" :rules="{ required: true }" v-slot="validationContext">
              <b-form-input
                v-model="existingAccountNumber"
                name="existingAccountNumber"
                debounce="500"
                class="form-control"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="existing-account-number-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Approximate amount owed: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <currency-input
              v-model="currentLenderAmountOwed"
              validationFieldName="approximate amount owed"
              id="current-lender-amount-owed"
              :required="true"
            ></currency-input>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-9">
            <h5>
              <label class="mandatory"> Is there an Early Repayment Charge (ERC) with this Mortgage? </label>
            </h5>
          </div>
          <div class="col-md-3">
            <ValidationProvider name="is there an ERC" rules="required|erc_present" v-slot="validationContext">
              <b-form-select
                v-model="earlyRepaymentPenaltyExists"
                :options="isThereAnErcOptions"
                id="erc-present"
                class="form-control bg-white"
                :state="getValidationState(validationContext)"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="erc-present-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div v-if="showErcQuestions">
          <div class="row pb-2">
            <div class="col-md-3">
              <h5>
                <label class="mandatory"> Penalty: </label>
              </h5>
            </div>

            <div class="col-md-9">
              <ValidationProvider
                name="early repayment penalty"
                :rules="{ required: !s_earlyRepaymentChargeUnknown }"
                v-slot="validationContext"
              >
                <b-form-input
                  v-model="earlyRepaymentPenalty"
                  :disabled="s_earlyRepaymentChargeUnknown"
                  name="earlyRepaymentPenalty"
                  debounce="500"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback data-error-name="early-repayment-penalty-error"
                  >{{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-md-3"></div>
            <div class="col-md-9">
              <ValidationProvider name="ERC penalty unknown" :rules="{ required: false }" v-slot="validationContext">
                <b-form-checkbox
                  v-model="earlyRepaymentChargeUnknown"
                  type="checkbox"
                  id="erc-penalty-unknown-confirm"
                  :state="getValidationState(validationContext)"
                  :aria-invalid="validationContext.errors.length"
                  >I don't know the penalty.</b-form-checkbox
                >
              </ValidationProvider>
            </div>
          </div>

          <div v-if="showErcEndDateQuestion" class="row pb-2">
            <div class="col-md-3">
              <h5>
                <label class="mandatory"> End date: </label>
              </h5>
            </div>

            <div class="col-md-9">
              <date-picker
                v-model="earlyRepaymentPenaltyEndDate"
                id="early-repayment-penalty-end-date"
                :required="true"
                minimumView="day"
                validationFieldName="ERC end date"
              />
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-md-9">
              <h5>
                <label class="mandatory"> Postpone completion until after penalty date? </label>
              </h5>
            </div>
            <div class="col-md-3">
              <ValidationProvider name="postpone completion" :rules="{ required: true }" v-slot="validationContext">
                <b-form-select
                  v-model="postponeCompletion"
                  :options="yesOrNo"
                  @change="confirmPostponeCompletion"
                  id="postpone-completion"
                  class="form-control bg-white"
                  :state="getValidationState(validationContext)"
                ></b-form-select>
                <b-form-invalid-feedback data-error-name="postpone-completion-error"
                  >{{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-9">
            <h5>
              <label class="mandatory">
                Are you making a lump sum overpayment with your {{ s_standaloneToe ? 'Mortgage' : 'Remortgage' }}?
              </label>
            </h5>
          </div>
          <div class="col-md-3">
            <ValidationProvider name="making overpayment" rules="required" v-slot="validationContext">
              <b-form-select
                v-model="makingOverpayment"
                :options="yesOrNo"
                id="making-overpayment"
                class="form-control bg-white"
                :state="getValidationState(validationContext)"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="making-overpayment-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div v-if="this.showOverpaymentAmount" class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Overpayment Amount: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <currency-input
              v-model="currentLenderOverpaymentAmount"
              validationFieldName="overpayment amount"
              id="current-lender-overpayment-amount"
              :required="true"
            ></currency-input>
          </div>
        </div>

        <div v-if="this.showOverpaymentAmount" class="row pb-2">
          <div class="col-md-12">
            <simple-question
              :dropdownOptions="enclosedOrToFollow"
              labelName="currentLenderBankStatementDocuments"
              title="Please supply copies of all relevant bank statements, dating back 6 months, for our proof of funds checks:"
              validationFieldName="bank statement documents"
              v-model="currentLenderBankStatementDocuments"
            ></simple-question>
            <inline-file-uploader
              v-if="currentLenderBankStatementDocuments.answer === 'ETF_ENCLOSED'"
              :requiredDocument="aboutYourRemortgage.currentLenderBankStatementDocuments"
            />
          </div>
        </div>

        <!-- Additional borrowing -->
        <div v-if="s_standaloneToe" class="row pb-2">
          <div class="col-md-9">
            <h5>
              <label class="mandatory"> Are you drawing down any additional borrowing? </label>
            </h5>
          </div>
          <div class="col-md-3">
            <ValidationProvider name="additional borrowing" rules="required" v-slot="validationContext">
              <b-form-select
                v-model="currentLenderAdditionalBorrowing"
                :options="yesOrNo"
                id="current-lender-additional-borrowing"
                class="form-control bg-white"
                :state="getValidationState(validationContext)"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="current-lender-additional-borrowing-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div v-if="this.showAdditionalBorrowingAmount" class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Additional borrowing amount: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <currency-input
              v-model="currentLenderAdditionalBorrowingAmount"
              validationFieldName="additional borrowing amount"
              id="current-lender-additional-borrowing-amount"
              :required="true"
            ></currency-input>
          </div>
        </div>
        <!-- End additional borrowing -->
      </div>
      <!-- / End current lender -->

      <!-- Additional lender -->
      <div v-if="showAdditionalLenderSection">
        <div class="row pb-2">
          <div class="col-md-12">
            <div class="section-title">
              <h3>Additional Lender</h3>
            </div>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-9">
            <h5>
              <label class="mandatory">Do you have an additional lender?</label>
            </h5>
          </div>
          <div class="col-md-3">
            <ValidationProvider name="additional lender status" rules="required" v-slot="validationContext">
              <b-form-select
                v-model="additionalLenderStatus"
                :options="yesOrNo"
                id="additional-lender-status"
                class="form-control bg-white"
                :state="getValidationState(validationContext)"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="additional-lender-status-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2" id="lender-name-status" v-if="this.showAdditionalLenderStatus">
          <div class="col-md-3">
            <h5>
              <label class="mandatory">Additional Lender </label>
            </h5>
          </div>
          <div class="col-md-9">
            <ValidationProvider name="additional lender name" rules="required" v-slot="validationContext">
              <v-select
                :value="additionalLenderNameOption"
                @input="setAdditionalLenderName"
                :options="lenders"
                id="additional-lender-name"
                class="bg-white"
                v-bind:class="{
                  'is-valid': !validationContext.errors.length,
                  'is-invalid': validationContext.errors.length,
                }"
                :state="getValidationState(validationContext)"
                ><span slot="no-options">{{ noMatchingLenderMessage }}</span></v-select
              >
              <div
                v-if="validationContext.errors"
                v-bind:class="{ 'invalid-feedback d-block': validationContext.errors }"
                data-error-name="additional-lender-name-select-error"
              >
                {{ validationContext.errors[0] }}
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div v-if="showAdditionalLenderNameFreeTextInput">
          <div class="row pb-2">
            <div class="col-md-3">
              <h5>
                <label class="mandatory"> Additional Lender (Other) </label>
              </h5>
            </div>
            <div class="col-md-9">
              <ValidationProvider name="additional lender name" rules="required" v-slot="validationContext">
                <b-form-input
                  v-model="additionalLenderName"
                  name="additionalLenderName"
                  debounce="500"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback data-error-name="additional-lender-name-other-error"
                  >{{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div v-if="showAdditionalLenderStatus">
          <div class="row pb-2">
            <div class="col-md-3">
              <h5>
                <label class="mandatory"> Additional lender account number: </label>
              </h5>
            </div>

            <div class="col-md-9">
              <ValidationProvider
                name="additional lender account number"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-input
                  v-model="additionalLenderAccountNumber"
                  name="additionalLenderAccountNumber"
                  debounce="500"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback data-error-name="additional-lender-account-number-error"
                  >{{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-md-3">
              <h5>
                <label class="mandatory"> Additional lender amount owed: </label>
              </h5>
            </div>

            <div class="col-md-9">
              <currency-input
                v-model="additionalLenderAmountOwed"
                validationFieldName="additional lender amount owed"
                id="additional-lender-amount-owed"
                :required="true"
              ></currency-input>
            </div>
          </div>

          <!-- Early Repayment Charge (ERC) -->

          <div class="row pb-2">
            <div class="col-md-9">
              <h5>
                <label class="mandatory"> Is there an Early Repayment Charge (ERC) with this Mortgage? </label>
              </h5>
            </div>
            <div class="col-md-3">
              <ValidationProvider
                name="is there an ERC for additional lender"
                rules="required|erc_present"
                v-slot="validationContext"
              >
                <b-form-select
                  v-model="additionalLenderEarlyRepaymentChargeExists"
                  :options="isThereAnErcOptions"
                  id="additional-lender-erc-present"
                  class="form-control bg-white"
                  :state="getValidationState(validationContext)"
                ></b-form-select>
                <b-form-invalid-feedback data-error-name="additional-lender-erc-present-error"
                  >{{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
          </div>

          <div v-if="additionalLenderShowErcQuestions">
            <div class="row pb-2">
              <div class="col-md-3">
                <h5>
                  <label class="mandatory"> Penalty: </label>
                </h5>
              </div>

              <div class="col-md-9">
                <ValidationProvider
                  name="early repayment penalty for additional lender"
                  :rules="{ required: !s_additionalLenderEarlyRepaymentChargeUnknown }"
                  v-slot="validationContext"
                >
                  <b-form-input
                    v-model="additionalLenderEarlyRepaymentCharge"
                    :disabled="s_additionalLenderEarlyRepaymentChargeUnknown"
                    name="additionalLenderEarlyRepaymentCharge"
                    debounce="500"
                    class="form-control"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback data-error-name="additional-lender-early-repayment-charge-error"
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </div>
            </div>

            <div class="row pb-2">
              <div class="col-md-3"></div>
              <div class="col-md-9">
                <ValidationProvider
                  name="ERC penalty unknown for additional lender"
                  :rules="{ required: false }"
                  v-slot="validationContext"
                >
                  <b-form-checkbox
                    v-model="additionalLenderEarlyRepaymentChargeUnknown"
                    type="checkbox"
                    id="additional-lender-erc-charge-unknown-confirm"
                    :state="getValidationState(validationContext)"
                    :aria-invalid="validationContext.errors.length"
                    >I don't know the penalty.</b-form-checkbox
                  >
                </ValidationProvider>
              </div>
            </div>

            <div v-if="additionalLenderShowErcEndDateQuestion" class="row pb-2">
              <div class="col-md-3">
                <h5>
                  <label class="mandatory"> End date: </label>
                </h5>
              </div>

              <div class="col-md-9">
                <date-picker
                  v-model="additionalLenderEarlyRepaymentChargeEndDate"
                  id="additional-lender-early-repayment-charge-end-date"
                  :required="true"
                  minimumView="day"
                  validationFieldName="ERC end date for additional lender"
                />
              </div>
            </div>

            <div class="row pb-2">
              <div class="col-md-9">
                <h5>
                  <label class="mandatory"> Postpone completion until after penalty date? </label>
                </h5>
              </div>
              <div class="col-md-3">
                <ValidationProvider
                  name="postpone completion for additional lender"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-select
                    v-model="additionalLenderEarlyRepaymentChargePostponeCompletion"
                    :options="yesOrNo"
                    @change="confirmPostponeCompletionAdditionalLender"
                    id="additional-lender-postpone-completion"
                    class="form-control bg-white"
                    :state="getValidationState(validationContext)"
                  ></b-form-select>
                  <b-form-invalid-feedback data-error-name="additional-lender-postpone-completion-error"
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <!-- / End: Early Repayment Charge (ERC) -->

          <div class="row pb-2">
            <div class="col-md-12">
              <h5>
                <label class="mandatory" for="additional-lender-keep"
                  >Do you want to repay or keep the additional lender or loan (please state the applicable answer with
                  any details below):</label
                >
              </h5>

              <ValidationProvider name="keep additional lender" :rules="{ required: true }" v-slot="validationContext">
                <b-form-textarea
                  v-model="additionalLenderKeep"
                  id="additional-lender-keep"
                  rows="2"
                  :state="getValidationState(validationContext)"
                ></b-form-textarea>
                <b-form-invalid-feedback
                  v-if="validationContext.errors[0]"
                  data-error-name="additional-lender-keep-error"
                  >{{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
          </div>

          <!-- Help To Buy Charge? -->
          <div class="row pb-2">
            <div class="col-md-12">
              <simple-question
                :dropdownOptions="yesOrNo"
                labelName="additionalLenderHelpToBuyCharge"
                title="Do you have a Help to Buy Charge?"
                validationFieldName="help to buy charge"
                v-model="additionalLenderHelpToBuyCharge"
                :removeSectionSpacing="true"
              ></simple-question>
            </div>
          </div>
          <!-- /end Help To Buy Charge? -->

          <!-- Show if Yes: Do you have a Help to Buy Charge? -->
          <div v-if="s_additionalLenderHelpToBuyCharge">
            <div class="row pb-2">
              <div class="col-md-12">
                <simple-question
                  :dropdownOptions="yesOrNo"
                  labelName="additionalLenderHelpToBuyChargePayingOff"
                  title="Are you paying off the help to buy charge?"
                  validationFieldName="paying off the help to buy charge"
                  v-model="additionalLenderHelpToBuyChargePayingOff"
                  :removeSectionSpacing="true"
                ></simple-question>
              </div>
            </div>

            <div v-if="s_additionalLenderHelpToBuyChargePayingOff" id="test">
              <div class="row pb-2">
                <div class="col-md-12">
                  <simple-question
                    :dropdownOptions="yesOrNo"
                    labelName="additionalLenderTargetHcaToPayOff"
                    title="Have you obtained a valuation and been in touch with Target HCA to notify them that you wish to pay off the loan?"
                    validationFieldName="a valuation and been in touch with Target HCA"
                    v-model="additionalLenderTargetHcaToPayOff"
                    :removeSectionSpacing="true"
                  ></simple-question>
                </div>
              </div>

              <div v-if="s_additionalLenderTargetHcaToPayOff === false">
                <div class="row pb-2">
                  <div class="col-md-12">
                    <div class="section-note mt-0">
                      <p>
                        <a
                          href="https://www.gov.uk/government/publications/repay-your-help-to-buy-equity-loan"
                          target="_blank"
                        >
                          https://www.gov.uk/government/publications/repay-your-help-to-buy-equity-loan
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="s_additionalLenderHelpToBuyChargePayingOff === false" id="test2">
              <div class="row pb-2">
                <div class="col-md-12">
                  <simple-question
                    :dropdownOptions="yesOrNo"
                    labelName="additionalLenderTargetHcaToRemortgage"
                    :title="targetHcaText"
                    validationFieldName="contacted Target HCA for consent"
                    v-model="additionalLenderTargetHcaToRemortgage"
                    :removeSectionSpacing="true"
                  ></simple-question>
                </div>
              </div>

              <div v-if="s_additionalLenderTargetHcaToRemortgage === false">
                <div class="row pb-2">
                  <div class="col-md-12">
                    <div class="section-note mt-0">
                      <p>
                        <a
                          href="https://www.gov.uk/guidance/how-to-remortgage-your-help-to-buy-home-without-borrowing-more-money"
                          target="_blank"
                          >How to remortgage your Help to Buy home without borrowing more money</a
                        >.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /End Show if Yes: Do you have a Help to Buy Charge? -->
        </div>
      </div>
      <!-- / End additional lender-->

      <!-- New lender -->
      <div v-if="showNewLenderSection">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title">
              <h3>New Lender</h3>
            </div>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> New Lender </label>
            </h5>
          </div>
          <div class="col-md-9">
            <ValidationProvider name="new lender name" rules="required" v-slot="validationContext">
              <v-select
                :value="newLenderNameOption"
                @input="setNewLenderName"
                :options="lenders"
                id="new-lender-name"
                class="bg-white"
                v-bind:class="{
                  'is-valid': !validationContext.errors.length,
                  'is-invalid': validationContext.errors.length,
                }"
                :state="getValidationState(validationContext)"
                ><span slot="no-options">{{ noMatchingLenderMessage }}</span></v-select
              >
              <div
                v-if="validationContext.errors"
                v-bind:class="{ 'invalid-feedback d-block': validationContext.errors }"
                data-error-name="new-lender-name-select-error"
              >
                {{ validationContext.errors[0] }}
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div v-if="showNewLenderNameFreeTextInput">
          <div class="row pb-2">
            <div class="col-md-3">
              <h5>
                <label class="mandatory"> New Lender (Other) </label>
              </h5>
            </div>
            <div class="col-md-9">
              <ValidationProvider name="new lender name" rules="required" v-slot="validationContext">
                <b-form-input
                  v-model="newLenderName"
                  name="newLenderName"
                  debounce="500"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback data-error-name="new-lender-name-other-error"
                  >{{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Amount of new mortgage: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <currency-input
              v-model="newLenderAmountOwed"
              validationFieldName="new mortgage amount"
              id="new-mortgage-amount"
              :required="true"
            ></currency-input>
          </div>
        </div>
      </div>
      <!-- / End new lender -->

      <div class="row pb-2">
        <div class="col-md-12">
          <div class="section-title">
            <h3>Permission to discuss your matter</h3>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-9">
          <h5>
            <label class="mandatory">
              Are you happy for us to pass a copy of your completion statement
              {{ this.s_standaloneToe && !this.s_existingMortgage ? '' : 'and redemption statement' }} to your mortgage
              broker (if requested by them) during the course of your matter?
            </label>
          </h5>
        </div>
        <div class="col-md-3">
          <ValidationProvider name="pass statements to broker" rules="required" v-slot="validationContext">
            <b-form-select
              v-model="passStatementsToBroker"
              :options="yesOrNo"
              id="pass-statements-to-broker"
              class="form-control bg-white"
              :state="getValidationState(validationContext)"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="pass-statements-to-broker-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>
      <modal v-if="showErcConfirmation" proceedButtonText="Yes" closeButtonText="No" @proceed="firstErcWarningHandler">
        <div class="text-center">
          <i class="icon-info3 icon-2x text-info border-info border-3 rounded-round p-3 mb-3 mt-1"></i>
          <h3>Are you sure?</h3>
          <p>Do you really want to complete during the penalty period?</p>
        </div>
      </modal>
      <modal
        v-if="showSecondErcConfirmation"
        proceedButtonText="Yes"
        closeButtonText="No"
        @proceed="secondErcWarningHandler"
      >
        <div class="text-center">
          <i class="icon-warning icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1"></i>
          <h3 class="text-warning">One last time</h3>
          <p>Are you absolutely sure? Completing during your penalty period can incur significant charges.</p>
        </div>
      </modal>
    </div>
  </validation-observer>
</template>

<style lang="scss" scoped></style>
