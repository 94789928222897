<script>
import { mapState, mapMutations } from 'vuex';
import patchRemortgage from '@/api/remortgages/patchRemortgage';
import * as remortgageMutations from '@/store/remortgage/mutations';
import * as personsMutations from '@/store/persons/mutations';
import patchTransferOfEquityPersons from '@/api/persons/patchTransferOfEquityPersons';
import TransferOfEquityPersonEditor from '@/components/forms/remortgageClientQuestionnaire/TransferOfEquityPersonEditor';
import validateToePersons from '@/functions/validateToePersons';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

export default {
  name: 'TransferOfEquityQuestionnaire',
  components: {
    TransferOfEquityPersonEditor,
  },
  data() {
    return {
      showEditInProgressWarning: false,
      showNoPersonsAddedWarning: false,
      showValidationAdditionalToePersonsError: null,
      showValidationRemovedToePersonsError: null,
      editingAdditionalToePersons: false,
      editingRemovedToePersons: false,
      yesOrNo,
    };
  },
  computed: {
    ...mapState({
      s_anyToePersonChanges: (state) => state.remortgage.anyToePersonChanges,
      s_additionalToePersons: (state) => state.persons.additionalToePersons,
      s_removedToePersons: (state) => state.persons.removedToePersons,
      s_remortgage: (state) => state.remortgage,
      s_standaloneToe: (state) => state.remortgage.standaloneToe,
    }),
    anyToePersonChanges: {
      get() {
        return this.s_anyToePersonChanges === true || this.s_anyToePersonChanges === 'true' || this.s_standaloneToe;
      },
      set(value) {
        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ANY_TOE_PERSON_CHANGES}`](
          value,
        );
      },
    },
    isEditing: {
      get() {
        return this.editingAdditionalToePersons || this.editingRemovedToePersons;
      },
    },
  },
  methods: {
    ...mapMutations([
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ANY_TOE_PERSON_CHANGES}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_ADDITIONAL_TOE_PERSON_ID}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_REMOVE_ADDITIONAL_TOE_PERSON_ID}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_UPDATE_ADDITIONAL_TOE_PERSON_ID}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_PERSONS_TO_BE_ADDED_TO_TITLE_IDS}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_REMOVED_TOE_PERSON_ID}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_REMOVE_REMOVED_TOE_PERSON_ID}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_UPDATE_REMOVED_TOE_PERSON_ID}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_PERSONS_TO_BE_REMOVED_FROM_TITLE_IDS}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_ADDITIONAL_TOE_PERSON_ADD}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_ADDITIONAL_TOE_PERSON_SET}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_ADDITIONAL_TOE_PERSON_ADD_ALL}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_ADDITIONAL_TOE_PERSON_REMOVE}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_REMOVED_TOE_PERSON_ADD}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_REMOVED_TOE_PERSON_SET}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_REMOVED_TOE_PERSON_ADD_ALL}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_REMOVED_TOE_PERSON_REMOVE}`,
    ]),
    async validatePersonsPayload(persons) {
      return await validateToePersons(persons)
        .then((response) => {
          return new Promise((resolve) => {
            resolve(response);
          });
        })
        .catch((error) => {
          return new Promise((reject) => {
            reject(error);
          });
        });
    },
    async addAdditionalToePerson(personData) {
      if (personData.index !== null) {
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_ADDITIONAL_TOE_PERSON_SET}`]({
          index: personData.index,
          value: { ...personData.value },
        });

        // Update only already edited additional TOE person data
        await patchTransferOfEquityPersons({
          persons: [{ ...personData.value }],
        });
      } else {
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_ADDITIONAL_TOE_PERSON_ADD}`]({
          ...personData.value,
        }); // Add new additional TOE person without person Id

        const personValue = [];
        personValue.push(personData.value);
        const newPersonsData = await patchTransferOfEquityPersons({
          // Patch new additional TOE person to get new created id for new additional TOE person
          persons: personValue,
        });

        const newPersonId = newPersonsData.map((item) => item.data.Id); // Extract person Ids

        this.s_additionalToePersons.forEach((person) => {
          // Update new additional TOE person with received person id
          if (typeof person.personId === 'undefined') {
            person.personId = newPersonId[0];
          }
        });
        await this.updateRemortgage();
      }
    },
    async addRemovedToePerson(personData) {
      if (personData.index !== null) {
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_REMOVED_TOE_PERSON_SET}`]({
          index: personData.index,
          value: { ...personData.value },
        });

        // Update only already edited removed TOE person data
        await patchTransferOfEquityPersons({
          persons: [{ ...personData.value }],
        });
      } else {
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_REMOVED_TOE_PERSON_ADD}`]({
          ...personData.value,
        }); // Add new removed TOE person without person Id

        const personValue = [];
        personValue.push(personData.value);
        const newPersonsData = await patchTransferOfEquityPersons({
          // Patch new removed TOE person to get new created id for new removed TOE person
          persons: personValue,
        });

        const newPersonId = newPersonsData.map((item) => item.data.Id); // Extract person Ids

        this.s_removedToePersons.forEach((person) => {
          // Update new removed TOE person with received person id
          if (typeof person.personId === 'undefined') {
            person.personId = newPersonId[0];
          }
        });
        await this.updateRemortgage();
      }
    },
    async setEditingAdditionalToePersons(value) {
      // When editing cleared, remove validation errors
      this.removeAllValidationWarnings();

      // Show/Hide person edit form
      this.editingAdditionalToePersons = value;
    },
    setEditingRemovedToePersons(value) {
      // When editing cleared, remove validation errors
      this.removeAllValidationWarnings();

      // Show/Hide person edit form
      this.editingRemovedToePersons = value;
    },
    async deletePersonToAdd(id) {
      await this[
        `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_REMOVE_ADDITIONAL_TOE_PERSON_ID}`
      ](id);
      await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_ADDITIONAL_TOE_PERSON_REMOVE}`](id);

      await this.updateRemortgage();
    },
    async deletePersonToRemove(id) {
      await this[
        `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_REMOVE_REMOVED_TOE_PERSON_ID}`
      ](id);
      await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_REMOVED_TOE_PERSON_REMOVE}`](id);

      await this.updateRemortgage();
    },
    async save() {
      const isValid = await this.validatePage();
      if (!isValid) {
        return false;
      }

      if (!this.anyToePersonChanges) {
        this.saving = true;
        await patchRemortgage({
          id: this.s_remortgage.remortgageId,
          personsToBeAddedToTitleIds: [],
          personsToBeRemovedFromTitleIds: [],
        });
        this.saving = false;
      }
      return true;
    },
    async validatePage() {
      const isAdditionalToePersonsValid = await this.validatePersonsPayload(this.s_additionalToePersons);
      const isRemovedToePersonsValid = await this.validatePersonsPayload(this.s_removedToePersons);
      this.removeAllValidationWarnings();

      if (this.isEditing) {
        this.showEditInProgressWarning = true;
        return false; // If the return result is false, tab switch is restricted
      } else if (isAdditionalToePersonsValid !== true || isRemovedToePersonsValid !== true) {
        this.showValidationAdditionalToePersonsError = isAdditionalToePersonsValid;
        this.showValidationRemovedToePersonsError = isRemovedToePersonsValid;
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      } else if (
        this.anyToePersonChanges &&
        this.s_additionalToePersons.length === 0 &&
        this.s_removedToePersons.length === 0
      ) {
        this.showNoPersonsAddedWarning = true;
        return false; // If the return result is false, tab switch is restricted
      }

      this.saving = false;
      return true;
    },
    removeAllValidationWarnings() {
      this.showValidationAdditionalToePersonsError = null;
      this.showValidationRemovedToePersonsError = null;
      this.showEditInProgressWarning = false;
      this.showNoPersonsAddedWarning = false;
    },
    async updateRemortgage() {
      const additionalToePersonIds = this.s_additionalToePersons.map((item) => item.personId);
      const removedToePersonIds = this.s_removedToePersons.map((item) => item.personId);
      await this[
        `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_PERSONS_TO_BE_ADDED_TO_TITLE_IDS}`
      ](additionalToePersonIds);
      await this[
        `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_PERSONS_TO_BE_REMOVED_FROM_TITLE_IDS}`
      ](removedToePersonIds);

      await patchRemortgage({
        id: this.s_remortgage.remortgageId,
        personsToBeAddedToTitleIds: additionalToePersonIds,
        personsToBeRemovedFromTitleIds: removedToePersonIds,
      });

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div>
    <ValidationObserver ref="observer">
      <h2>Transfer of Equity</h2>

      <div class="row pb-2 pt-4">
        <div class="col-md-12">
          <div class="section-note">
            <p>
              A transfer of equity involves the removal, or the adding, of people named on the legal title of a
              property. For example, this could be one owner adding a husband or wife, a parent adding a son or
              daughter, or a separating couple removing one of them from the property ownership. It is important to
              inform us of any changes of ownership that should take place as part of your
              {{ s_standaloneToe ? 'transfer of equity' : 'remortgage' }}.
            </p>
          </div>
        </div>
      </div>

      <div class="row" v-if="!s_standaloneToe">
        <div class="col-md-9">
          <h5>
            <label class="mb-2">
              Are there any additional people to be added/removed from the mortgage title that have not been declared in
              the Contact Section?
            </label>
          </h5>
        </div>
        <div class="col-md-3">
          <ValidationProvider
            name="additional people to be added/removed"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-select
              v-model="anyToePersonChanges"
              :options="yesOrNo"
              id="any-toe-person-changes"
              class="form-control bg-white"
              :state="getValidationState(validationContext)"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="any-toe-person-changes"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="anyToePersonChanges">
        <div class="section-title">
          <h3>Persons to be added to title</h3>
        </div>

        <transfer-of-equity-person-editor
          :toe-persons="this.s_additionalToePersons"
          :displayToeAmount="true"
          :showEditInProgressWarning="showEditInProgressWarning"
          @deletePerson="deletePersonToAdd($event)"
          @addPerson="addAdditionalToePerson($event)"
          @setEditing="setEditingAdditionalToePersons($event)"
        >
          <template v-slot:title-text>
            <h5><label>Click or tap the button to add a "Person to be added to title":</label></h5>
          </template>
        </transfer-of-equity-person-editor>

        <div
          v-if="
            showValidationAdditionalToePersonsError !== null &&
            !editingAdditionalToePersons &&
            !editingRemovedToePersons
          "
        >
          <p
            class="text-danger mt-1 mb-1"
            v-for="(item, index) in this.showValidationAdditionalToePersonsError"
            :key="index"
          >
            {{ item }}
          </p>
        </div>

        <div class="section-title">
          <h3>Persons to be removed from title</h3>
        </div>

        <transfer-of-equity-person-editor
          :toe-persons="this.s_removedToePersons"
          :displayToeAmount="true"
          :showEditInProgressWarning="showEditInProgressWarning"
          @deletePerson="deletePersonToRemove($event)"
          @addPerson="addRemovedToePerson($event)"
          @setEditing="setEditingRemovedToePersons($event)"
        >
          <template v-slot:title-text
            ><h5><label>Click or tap the button to add a "Person to be removed from title":</label></h5></template
          >
        </transfer-of-equity-person-editor>

        <div
          v-if="
            showValidationRemovedToePersonsError !== null && !editingAdditionalToePersons && !editingRemovedToePersons
          "
        >
          <p
            class="text-danger mt-1 mb-1"
            v-for="(item, index) in this.showValidationRemovedToePersonsError"
            :key="index"
          >
            {{ item }}
          </p>
        </div>

        <div class="border-top my-3"></div>

        <div class="row pb-2" v-if="showNoPersonsAddedWarning">
          <div class="col-md-12">
            <h6 class="save-warning">
              Please provide at least one person to add or remove from the title before proceeding.
            </h6>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
