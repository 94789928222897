<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex';
import FormWrapper from '@/components/FormWrapper';
import Modal from '@/components/Modal';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import AboutYou from '@/components/forms/remortgageClientQuestionnaire/RemortgageAboutYou';
import AboutYourRemortgage from '@/components/forms/remortgageClientQuestionnaire/AboutYourRemortgage';
import TransferOfEquity from '@/components/forms/remortgageClientQuestionnaire/TransferOfEquityQuestionnaire';
import Occupiers from '@/components/forms/remortgageClientQuestionnaire/OccupiersQuestionnaire';
import Other from '@/components/forms/remortgageClientQuestionnaire/OtherQuestionnnaire';
import SummaryPage from '@/components/forms/SummaryPage';
import PdfPreview from '@/components/forms/RemortgagePDFpreview';
import HmlrClientNameMismatch from '@/components/messaging/HmlrClientNameMismatch';
import HmlrPending from '@/components/messaging/HmlrPending';
import SaveProgressInfo from '@/components/messaging/SaveProgressInfo';
import RemortgageReviewPage from '@/components/forms/remortgageClientQuestionnaire/RemortgageReviewPage';
import * as remortgageActions from '@/store/remortgage/actions';
import {
  AWAITING_RESPONSE_DOCUMENT_ANALYSIS,
  AWAITING_RESPONSE_HMLR,
  NAME_MISMATCH,
  READY_TO_SUBMIT,
  SUBMISSION_NOT_INITIATED,
} from '@/values/cqSubmissionStatuses';
import FormCompletion from '@/mixins/FormCompletion';

const aboutYouTabIndex = 0;
const transferOfEquityTabIndex = 2;
const reviewPageTabIndex = 6;

// Config to set number of times to poll the form submission status
// before timing out. We allow a reasonable amount of time because
// we need to wait for the fetching of the HMLR title and subsequent
// document analysis to complete.
const statusPollInterval = 3000;
const maxPollPeriod = 90000;
const maxPollAttempts = maxPollPeriod / statusPollInterval;

export default {
  name: 'RemortgageClientQuestionnaire',
  components: {
    FormWrapper,
    FormWizard,
    TabContent,
    WizardButton,
    Modal,
    AboutYou,
    AboutYourRemortgage,
    TransferOfEquity,
    Occupiers,
    Other,
    SummaryPage,
    PdfPreview,
    SaveProgressInfo,
    RemortgageReviewPage,
    HmlrClientNameMismatch,
    HmlrPending,
  },
  mixins: [FormCompletion],
  data() {
    return {
      disabled: false,
      clientQuestionnaireForm: 0,
      showSubmitModal: false,
      wizardLoading: false,
      hideWizardNextButton: false,
      avoidForceRerender: false,
      amendingPersonsOnTitle: false,
      // Are we currently submitting the form?
      isSubmitting: false,
      // Is the user submitting despite a name mismatch?
      isForceSubmit: false,
      pollAttemptNo: 0,
    };
  },
  props: ['entityType', 'entityId'],
  methods: {
    ...mapActions('remortgage', ['addTrackingEvent', 'setEventToHandle']),
    setLoading(value) {
      this.wizardLoading = value;
    },
    forceRerender() {
      this.clientQuestionnaireForm++;
    },
    validatePerson: function () {
      return this.$refs.aboutYou.validatePersonsPayload();
    },
    saveRemortgage: function () {
      return this.$refs.aboutYourRemortgage.saveToApi();
    },
    validateOccupiers: function () {
      return this.$refs.occupiers.validatePage();
    },
    validateTransferOfEquity: function () {
      return this.$refs.transferOfEquity.save();
    },
    validateReviewConfirmation: function () {
      return this.$refs.reviewPage.validateConfirmation();
    },
    validateOther: function () {
      return this.$refs.other.saveToApi();
    },
    showPdfPreview: function (downloadFile = false) {
      return this.$refs.pdfPreview.getPdf(downloadFile, 'remortgage-questionnaire-form-');
    },
    saveAndRedirect: async function () {
      this.navigate('ViewEntity', {
        entityType: this.entityType,
        entityId: this.entityId,
      });
    },
    saveAndShowModalBeforeSubmit: async function () {
      this.showSubmitModal = true;
    },
    async trySubmitForm(value) {
      this.showSubmitModal = false;
      if (value) {
        // Reset the submission status so we don't immediately see name mismatches
        // from previous submission attempts.
        await this.$store.dispatch(remortgageActions.REMORTGAGE_RESET_SUBMISSION_STATUS);
        this.disabled = true;
        this.isSubmitting = true;
        this.amendingPersonsOnTitle = false;

        try {
          // Check if the user has specified a reason for a mismatch between
          // the names of the client(s) and proprietor(s) and, if so, include
          // it in the form submission payload.
          this.isForceSubmit = typeof value === 'object' && value.hasOwnProperty('reason');
          const reason = this.isForceSubmit ? value.reason : null;
          const additionalInfo = this.isForceSubmit ? value.additionalInfo : null;
          const actionPayload = { remortgageId: this.entityId, reason, additionalInfo };

          await this.$store.dispatch(remortgageActions.REMORTGAGE_TRY_SUBMIT, actionPayload);
          this.isForceSubmit = false;
          this.processFormSubmissionStatus();

          // NB: See also the 'displayPendingDialog' watch function, which
          // handles initiation of polling of the submission status when
          // the user navigates back to this page.
        } catch (err) {
          console.debug(err);
        }
      }
    },
    processFormSubmissionStatus() {
      // If we're awaiting a response, check the status again in a few seconds.
      // We also do this if the status is 'submission_not_initiated' but
      // isSubmitting is true, as the background job which orders
      // the HMLR documents can take a few seconds to start.
      if (this.pollSubmissionStatus) {
        if (!this.pollTimedOut) {
          this.pollAttemptNo++;

          setTimeout(async () => {
            await this.$store.dispatch(remortgageActions.REMORTGAGE_FETCH_SUBMISSION_STATUS, this.entityId);
            this.processFormSubmissionStatus();
          }, statusPollInterval);
        }
        return;
      }

      // In all other cases, we're not waiting for any further response, so
      // we can reset the poll count.
      this.isSubmitting = false;
      this.isForceSubmit = false;
      this.resetRemainingPollAttempts();

      // If submission was successful, navigate to the remortgage case overview.
      if (this.s_submissionStatus === 'submitted') {
        this.completeFormSubmission();
      }
    },
    completeFormSubmission() {
      this.$gtag.event('Completed Milestone', {
        event_category: 'Task',
        event_label: 'Completed Remortgage Questionnaire',
      });

      this.setEventToHandle('completed_questionnaire');

      this.navigate('ViewEntity', {
        entityType: this.entityType,
        entityId: this.entityId,
      });

      this.disabled = false;
    },
    scrollToTop() {
      if (this.$router.currentRoute.name == 'ViewEntity') {
        this.$scrollTo('.page-header');
      }
    },
    allowForceRerender(value) {
      this.avoidForceRerender = value;
    },
    personAddEditMode(val) {
      // Switch of wizard next button when user is in Edit or Add person mode
      this.hideWizardNextButton = val;
    },
    amendPersonsOnTitle() {
      // Switch form wizard to 'About You' tab
      this.switchTabAndAmend(aboutYouTabIndex);
    },
    amendRemovedPersons() {
      // Switch form wizard to 'Transfer Of Equity' tab
      this.switchTabAndAmend(transferOfEquityTabIndex);
    },
    switchTabAndAmend(newIndex) {
      this.amendingPersonsOnTitle = true;
      this.disabled = false;

      const formWizardRef = this.$refs.remortgageCQWizard;
      const oldIndex = formWizardRef.activeTabIndex;
      formWizardRef.changeTab(oldIndex, newIndex);
    },
    resetRemainingPollAttempts() {
      this.pollAttemptNo = 0;
    },
  },
  watch: {
    loading: function () {
      if (!this.avoidForceRerender) {
        this.forceRerender();
      }
    },
    pollSubmissionStatus: function (newValue) {
      // We use a watch to ensure that polling is initiated even
      // if the user navigates back to the questionnaire.
      if (newValue) {
        this.processFormSubmissionStatus();
      } else {
        this.resetRemainingPollAttempts();
      }
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      s_submissionStatus: (state) => state.remortgage.submissionStatus,
      s_matchDetails: (state) => state.remortgage.clientMatchDetails,
      s_titleDocumentId: (state) => state.remortgage.hmlrTitleDocumentId,
      s_removedToePersonIds: (state) => state.remortgage.personsToBeRemovedFromTitleIds,
      s_standaloneToe: (state) => state.remortgage.standaloneToe,
    }),
    displayPendingDialog: {
      get() {
        return (
          this.s_submissionStatus === AWAITING_RESPONSE_HMLR ||
          this.s_submissionStatus === AWAITING_RESPONSE_DOCUMENT_ANALYSIS
        );
      },
    },
    displayClientNameMismatchDialog: {
      get() {
        return this.s_submissionStatus === NAME_MISMATCH && !this.isForceSubmit && !this.amendingPersonsOnTitle;
      },
    },
    pollSubmissionStatus: {
      get() {
        // Has the user submitted but we're still awaiting a first response from the API?
        const submissionCommenced =
          this.isSubmitting &&
          (this.s_submissionStatus === SUBMISSION_NOT_INITIATED || this.s_submissionStatus === READY_TO_SUBMIT);
        // Or, alternatively, are we awaiting a response from HMLR/document analysis?
        return submissionCommenced || this.displayPendingDialog;
      },
    },
    pollTimedOut: {
      get() {
        return this.pollAttemptNo > maxPollAttempts;
      },
    },
    hasRemovedToePersons: {
      get() {
        return this.s_removedToePersonIds && this.s_removedToePersonIds.length > 0;
      },
    },
    showSaveProgressModal: {
      get() {
        // Only show the 'save progress' modal if user hasn't initiated the form submission process
        return !(this.displayPendingDialog || this.displayClientNameMismatchDialog || this.amendingPersonsOnTitle);
      },
    },
    // The form tab to start on
    startIndex: {
      get() {
        // We should start on the first tab unless the user has initiated the form submission process
        const submitAttempted = this.displayPendingDialog || this.displayClientNameMismatchDialog;
        return submitAttempted ? reviewPageTabIndex : aboutYouTabIndex;
      },
    },
  },
};
</script>

<template>
  <form-wrapper :entityType="entityType" :entityId="entityId" class="mb-5">
    <div class="home" slot="form-content">
      <div :key="clientQuestionnaireForm">
        <form-wizard
          ref="remortgageCQWizard"
          title=""
          subtitle=""
          :start-index="startIndex"
          validatePage
          :color="$t('site.formColour')"
          errorColor="#F44336"
          @on-loading="setLoading"
          @on-change="scrollToTop"
          stepSize="xs"
        >
          <tab-content title="About You" :before-change="validatePerson">
            <about-you @personAddEditMode="personAddEditMode" ref="aboutYou"></about-you>
            <save-progress-info v-if="showSaveProgressModal" />
          </tab-content>

          <tab-content
            :title="s_standaloneToe ? 'Property Details' : 'Mortgage Details'"
            :before-change="saveRemortgage"
          >
            <about-your-remortgage ref="aboutYourRemortgage"></about-your-remortgage>
          </tab-content>

          <tab-content title="Transfer of Equity" :before-change="validateTransferOfEquity">
            <transfer-of-equity ref="transferOfEquity"></transfer-of-equity>
          </tab-content>

          <tab-content title="Occupiers" :before-change="validateOccupiers">
            <occupiers ref="occupiers"></occupiers>
          </tab-content>

          <tab-content title="Additional Information" :before-change="validateOther">
            <other ref="other"></other>
          </tab-content>

          <tab-content title="Review" :before-change="validateReviewConfirmation">
            <remortgage-review-page ref="reviewPage"></remortgage-review-page>
          </tab-content>

          <tab-content title="Summary">
            <summary-page
              ref="summary"
              :disabled="disabled"
              :entityType="entityType"
              :standaloneToe="s_standaloneToe"
              @preview="showPdfPreview"
              @download="showPdfPreview(true)"
              @save="saveAndRedirect"
              @submit="saveAndShowModalBeforeSubmit"
            ></summary-page>
          </tab-content>

          <!-- Customised Buttons -->
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left">
              <wizard-button
                v-if="props.activeTabIndex > 0"
                class="w-100 w-sm-auto bg-light border-dashed text-primary"
                @click.native="props.prevTab()"
                :style="props.fillButtonStyle"
              >
                <i class="icon-arrow-left5"></i> Previous
              </wizard-button>
            </div>
            <div class="wizard-footer-right">
              <div v-if="!props.isLastStep">
                <div v-if="!hideWizardNextButton">
                  <wizard-button
                    @click.native="props.nextTab()"
                    class="wizard-footer-right w-100 w-sm-auto"
                    :style="props.fillButtonStyle"
                    :disabled="wizardLoading"
                  >
                    Save and Continue
                    <i v-if="wizardLoading" class="icon-spinner11 ml-2 spinner"></i>
                    <i v-else class="icon-arrow-right5"></i>
                  </wizard-button>
                </div>
              </div>
              <div v-if="displayPendingDialog">
                <hmlr-pending :status="s_submissionStatus" :timed-out="pollTimedOut"></hmlr-pending>
              </div>
              <div v-if="displayClientNameMismatchDialog">
                <hmlr-client-name-mismatch
                  :name-matches="s_matchDetails"
                  :title-document-id="s_titleDocumentId"
                  :show-amend-removed-persons-button="hasRemovedToePersons"
                  @amendPersonsOnTitle="amendPersonsOnTitle"
                  @amendRemovedPersons="amendRemovedPersons"
                  @submit="trySubmitForm"
                >
                </hmlr-client-name-mismatch>
              </div>
              <div v-else>
                <pdf-preview
                  form-name="RemortgageCQ"
                  ref="pdfPreview"
                  :entityType="entityType"
                  :entityId="entityId"
                  @allowForceRerender="allowForceRerender"
                ></pdf-preview>
                <modal
                  v-if="showSubmitModal"
                  closeButtonText="Close"
                  proceedButtonText="Save And Submit"
                  @proceed="trySubmitForm"
                  >Be aware that once you "Save And Submit", the form will be locked for any changes. Do you want submit
                  this form?
                </modal>
              </div>
            </div>
          </template>
        </form-wizard>
      </div>
    </div>
  </form-wrapper>
</template>

<style lang="scss" />
