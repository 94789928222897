import axios from 'axios';

export default ({ persons }) => {
  return new Promise((resolve, reject) => {
    Promise.all(
      persons.map((item) => {
        let personData = {
          title: item.title,
          first_name: item.firstName,
          middle_name: item.middleName,
          no_middle_name_confirmed: item.noMiddleNameConfirmed,
          last_name: item.lastName,
          email: item.email,
          phone_mobile: item.phoneMobile,
          dob: item.dob,
        };

        const isItToeAnyMoneyPaidForTransfer =
          (typeof item.toeAnyMoneyPaidForTransfer === 'boolean' && item.toeAnyMoneyPaidForTransfer) ||
          item.toeAnyMoneyPaidForTransfer === 'true';

        const isItToeTransferByCourtOrder =
          (typeof item.toeTransferByCourtOrder === 'boolean' && item.toeTransferByCourtOrder) ||
          item.toeTransferByCourtOrder === 'true';

        personData.profile_transfer_of_equity = {
          toe_any_money_paid_for_transfer: item.toeAnyMoneyPaidForTransfer,
          toe_amount: isItToeAnyMoneyPaidForTransfer ? Number(item.toeAmount) : null,
          toe_married_or_civil_partnership_with_title_person: item.toeMarriedOrCivilPartnershipWithTitlePerson,
          toe_own_other_property: item.toeOwnOtherProperty,
          toe_is_primary_residence: item.toeIsPrimaryResidence,
          toe_transfer_by_court_order: item.toeTransferByCourtOrder,
          toe_transfer_by_court_order_documents: isItToeTransferByCourtOrder
            ? item.toeTransferByCourtOrderDocuments
            : null,
        };

        personData = Object.entries(personData).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});
        if (item.hasOwnProperty('personId')) {
          return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/persons/${item.personId}`, personData);
        } else {
          return axios.post(`${process.env.VUE_APP_API_ENDPOINT}/persons`, personData);
        }
      }),
    )
      .then((resultData) => {
        resolve(resultData);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};
