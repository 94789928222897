<script>
import { mapState, mapMutations } from 'vuex';
import patchRemortgage from '@/api/remortgages/patchRemortgage';
import * as remortgageMutations from '@/store/remortgage/mutations';
import * as personsMutations from '@/store/persons/mutations';
import * as occupiersMutations from '@/store/occupiers/mutations';
import patchOccupiersPersons from '@/api/persons/patchOccupiersPersons';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const titles = [
  { value: null, text: 'Please choose', disabled: true },
  'Mr',
  'Mrs',
  'Miss',
  'Ms',
  'Dr',
  'Sir',
  'Mx',
  'Rev',
  'Lord',
  'Lady',
  'Prof',
  'NA',
];

const rawOccupier = {
  title: null,
  firstName: '',
  middleName: '',
  noMiddleNameConfirmed: false,
  lastName: '',
  age: null,
  phoneMobile: null,
  email: null,
  relationship: null,
  relationshipOther: '',
};

const relationships = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'Spouse', text: 'Spouse' },
  { value: 'Partner', text: 'Partner' },
  { value: 'Child', text: 'Child' },
  { value: 'Relative', text: 'Relative' },
  { value: 'Tenant', text: 'Tenant' },
  { value: 'Other', text: 'Other' },
];

export default {
  name: 'OccupiersQuestionnaire',
  data() {
    return {
      editing: false,
      editingIndex: null,
      yesOrNo,
      titles,
      rawOccupier,
      relationships,
      localData: {
        personId: null,
        title: null,
        firstName: '',
        middleName: '',
        noMiddleNameConfirmed: false,
        lastName: '',
        phoneMobile: null,
        age: null,
        email: null,
        relationship: null,
        relationshipOther: '',
      },
      invalid: false,
      emailIsRequired: true,
      phoneMobileIsRequired: true,
    };
  },
  computed: {
    ...mapState({
      s_occupiers: (state) => state.persons.occupiers,
      s_remortgage: (state) => state.remortgage,
      s_standaloneToe: (state) => state.remortgage.standaloneToe,
    }),
    seventeenOrOverLivesAtProperty: {
      get() {
        return this.s_occupiers.length > 0;
      },
      set(value) {
        this[
          `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_SEVENTEEN_OR_OVER_LIVES_AT_PROPERTY}`
        ](value);
      },
    },
    middleNameSet: function () {
      if (!this.localData) {
        return false;
      }

      return this.localData?.middleName?.length > 0;
    },
    showSaveWarning: {
      get() {
        return this.invalid && this.editing;
      },
    },
  },
  methods: {
    ...mapMutations([
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_OCCUPIER_ID}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_REMOVE_OCCUPIER_ID}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_UPDATE_OCCUPIER_ID}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_OCCUPIER_IDS}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_OCCUPIER_ADD}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_OCCUPIER_SET}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_OCCUPIER_ADD_ALL}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_OCCUPIER_REMOVE}`,
      `${occupiersMutations.OCCUPIERS_PREFIX}${occupiersMutations.OCCUPIERS_SET_SEVENTEEN_OR_OVER_LIVES_AT_PROPERTY}`,
    ]),
    async addPerson() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.invalid = true;
        return false; // If the return result is false, tab switch is restricted
      }

      if (this.editingIndex !== null) {
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_OCCUPIER_SET}`]({
          index: this.editingIndex,
          value: { ...this.localData },
        });

        // Update only already edited occupier data
        await patchOccupiersPersons({ persons: [{ ...this.localData }] });
      } else {
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_OCCUPIER_ADD}`]({
          ...this.localData,
        }); // Add new occupier without person Id

        const personValue = [];
        personValue.push(this.localData);
        const newPersonsData = await patchOccupiersPersons({
          // Patch new occupier to get new created id for new occupier
          persons: personValue,
        });

        const newPersonId = newPersonsData.map((item) => item.data.Id); // Extract person Ids

        this.s_occupiers.forEach((occupier) => {
          // Update new occupier with received person id
          if (typeof occupier.personId === 'undefined') {
            occupier.personId = newPersonId[0];
          }
        });
        await this.updateRemortgage();
      }
      this.editing = false;
      this.editingIndex = null;
      this.localData = { ...rawOccupier };
    },
    editPerson(index) {
      this.localData = { ...this.s_occupiers[index] };
      this.editing = true;
      this.editingIndex = index;
    },
    cancelEditing() {
      this.editing = false;
      this.editingIndex = null;
      this.localData = { ...rawOccupier };
    },
    enableEditing() {
      this.editing = true;
    },
    async deletePerson(id) {
      await this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_REMOVE_OCCUPIER_ID}`](id);
      await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_OCCUPIER_REMOVE}`](id);

      await this.updateRemortgage();
    },
    async validatePage() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid || this.editing) {
        this.invalid = true;
        if (!isValid) {
          this.$el.querySelector('[aria-invalid=true]').focus();
        } else if (this.editing) {
          this.scrollToSelector('.save-warning');
        }
        return false; // If the return result is false, tab switch is restricted
      }

      this.invalid = false;
      this.saving = false;
      return true;
    },
    async updateRemortgage() {
      const remortgageOccupiersIds = this.s_occupiers.map((item) => item.personId);

      await this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_OCCUPIER_IDS}`](
        remortgageOccupiersIds,
      );
      await patchRemortgage({
        id: this.s_remortgage.remortgageId,
        occupierIds: remortgageOccupiersIds,
      });

      this.saving = false;

      return true; // If the return result is true, tab switch is allowed
      // @TODO: Implement Liam's error handling module
      // .catch((error) => console.error(error));
    },
    getValidationState({ dirty, validated, valid = null }) {
      this.emailIsRequired = !(
        this.localData?.phoneMobile !== '' &&
        this.localData?.phoneMobile !== null &&
        typeof this.localData?.phoneMobile !== 'undefined'
      );
      this.phoneMobileIsRequired = !(
        this.localData?.email !== '' &&
        this.localData?.email !== null &&
        typeof this.localData?.email !== 'undefined'
      );

      return dirty || validated ? valid : null;
    },
  },
  watch: {
    s_occupiers() {
      this.seventeenOrOverLivesAtProperty = this.s_occupiers.length > 0;
    },
  },
  mounted() {
    this.localData = { ...rawOccupier };
  },
};
</script>

<template>
  <div class="col-md-12">
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <h2>Occupiers</h2>

      <div class="row pb-2 pt-4">
        <div class="col-md-12">
          <div class="section-note">
            <p>
              You'll need to inform us if there will be any adults living in the property who are not going to be
              owners. For example, any grown-up children, age 17 or over, who have not left home. This is a requirement
              of your lender to be informed of any adult occupants. Please note you do not need to include people who
              already appear on the title.
            </p>
          </div>
        </div>
      </div>

      <div class="row pb-2 pt-4">
        <div class="col-md-9">
          <h5>
            Please add all adults (aged 17 or over) living at the property{{
              s_standaloneToe ? '' : ' to be remortgaged'
            }}, other than those already named as owners:
          </h5>
        </div>
        <div class="col-md-3 d-flex justify-content-end">
          <button :disabled="invalid" @click="enableEditing" class="btn btn-add" v-if="!editing" />
        </div>
      </div>
      <div v-if="showSaveWarning">
        <div class="row pb-2">
          <div class="col-md-12">
            <h5 class="save-warning">Please save or discard any changes before continuing.</h5>
          </div>
        </div>
      </div>
      <div class="border-top my-3"></div>
      <div v-if="editing">
        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Title </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="occupier title" rules="required" v-slot="validationContext">
              <b-form-select
                :options="titles"
                :state="getValidationState(validationContext)"
                class="form-control bg-white"
                id="occupier_title"
                v-model="localData.title"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="title-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> First Name: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="first name"
              :rules="{ required: true, person_name: 'first name' }"
              v-slot="validationContext"
            >
              <b-form-input
                :state="getValidationState(validationContext)"
                class="form-control"
                id="first-name"
                v-model="localData.firstName"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="first-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label> Middle Name(s): </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="middle name" :rules="{ person_name: 'middle name' }" v-slot="validationContext">
              <b-form-input
                :state="getValidationState(validationContext)"
                :disabled="localData.noMiddleNameConfirmed"
                class="form-control"
                id="middle-name"
                v-model="localData.middleName"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="middle-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2" v-if="!middleNameSet">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <ValidationProvider
              name="middle name confirm"
              :rules="{ required: { allowFalse: false } }"
              v-slot="validationContext"
            >
              <b-form-checkbox
                v-model="localData.noMiddleNameConfirmed"
                type="checkbox"
                id="middle-name-confirm"
                :state="getValidationState(validationContext)"
                :aria-invalid="validationContext.errors[0] !== null"
                >Please confirm that you do not have a middle name.</b-form-checkbox
              >
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Last Name: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="last name"
              :rules="{ required: true, person_name: 'last name' }"
              v-slot="validationContext"
            >
              <b-form-input
                :state="getValidationState(validationContext)"
                class="form-control"
                id="last-name"
                v-model="localData.lastName"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="last-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Age: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="age" rules="required|numeric|seventeen_or_over" v-slot="validationContext">
              <b-form-input
                :state="getValidationState(validationContext)"
                class="form-control"
                id="age"
                type="number"
                v-model="localData.age"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="age-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Relationship: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="relationship" rules="required" v-slot="validationContext">
              <b-form-select
                :options="relationships"
                :state="getValidationState(validationContext)"
                class="form-control bg-white"
                id="relationship"
                v-model="localData.relationship"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="relationship-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2" v-if="localData.relationship === 'Other'">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Other Relationship: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="other relationship" rules="required|alpha_spaces" v-slot="validationContext">
              <b-form-input
                :state="getValidationState(validationContext)"
                class="form-control"
                id="relationship-other"
                v-model="localData.relationshipOther"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="relationship-other-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Email Address: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="email" :rules="{ required: true, email: true }" v-slot="validationContext">
              <b-form-input
                :state="getValidationState(validationContext)"
                class="form-control"
                id="email"
                type="email"
                v-model="localData.email"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="email-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label> Mobile Telephone: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="mobile number"
              :rules="{ required: false, mobile_number: true }"
              v-slot="validationContext"
            >
              <b-form-input
                v-model="localData.phoneMobile"
                id="phone-mobile"
                class="form-control"
                :state="getValidationState(validationContext)"
                type="tel"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="phone-mobile-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="row pb-1 justify-content-end">
        <div class="col-md-3">
          <button @click="cancelEditing" v-if="editing" class="btn btn-cancel btn-block mb-1" />
        </div>
        <div class="col-md-3">
          <button v-if="editing" @click="addPerson" class="btn btn-save btn-block" />
        </div>
      </div>

      <div>
        <b-list-group v-if="!editing && s_occupiers.length !== 0" class="p-0 mt-1" :flush="true">
          <b-list-group-item variant="light">
            <div class="col-6 d-inline-block col-md-4">Name</div>
            <div class="col-6 d-inline-block col-md-8">Age</div>
          </b-list-group-item>
          <b-list-group-item
            v-for="(item, index) in s_occupiers"
            :key="index"
            :class="{ 'bg-teal': editing && item.personId === editingPersonId }"
            class="py-2 px-0"
            variant="light"
          >
            <div class="col-6 d-inline-block col-md-4">
              {{ item.firstName }} {{ item.middleName }} {{ item.lastName }}
            </div>
            <div class="col-6 d-inline-block col-md-4">
              {{ item.age }}
            </div>
            <div class="d-lg-inline-block col-lg-4">
              <div class="d-flex justify-content-center justify-content-lg-end">
                <button class="btn btn-edit mr-1" @click="editPerson(index)"></button>
                <button class="btn btn-remove" @click="deletePerson(item.personId)"></button>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
        <p class="text-muted" v-else>No occupiers have been added.</p>
      </div>
      <div class="border-top my-3"></div>
    </ValidationObserver>
  </div>
</template>
