import axios from 'axios';
import { ERC_EXISTS_OPTION_YES_KNOWN, ERC_EXISTS_OPTION_YES_UNKNOWN } from '../../values/ercExists';

export default ({
  id,
  additionalInfo,
  additionalLenderAccountNumber,
  additionalLenderAmountOwed,
  additionalLenderKeep,
  additionalLenderName,
  additionalLenderHelpToBuyCharge,
  additionalLenderHelpToBuyChargePayingOff,
  additionalLenderTargetHcaToPayOff,
  additionalLenderTargetHcaToRemortgage,
  additionalLenderEarlyRepaymentCharge,
  additionalLenderEarlyRepaymentChargeEndDate,
  additionalLenderEarlyRepaymentChargeExists,
  additionalLenderEarlyRepaymentChargePostponeCompletion,
  additionalLenderEarlyRepaymentChargeUnknown,
  address,
  bankName,
  bankAccountName,
  bankAccountNumber,
  bankBacsOrChaps,
  bankSortCode,
  clientIds,
  currentLenderAccountNumber,
  currentLenderAmountOwed,
  currentLenderMakingOverpayment,
  currentLenderOverpaymentAmount,
  currentLenderBankStatementDocuments,
  currentLenderAdditionalBorrowing,
  currentLenderAdditionalBorrowingAmount,
  currentLenderName,
  earlyRepaymentCharge,
  earlyRepaymentChargeEndDate,
  earlyRepaymentChargeExists,
  earlyRepaymentChargePostponeCompletion,
  earlyRepaymentChargeUnknown,
  leaseholdAdditionalInfo,
  newLenderAmountOwed,
  newLenderName,
  occupierIds,
  passStatementsToBroker,
  personsToBeAddedToTitleIds,
  personsToBeRemovedFromTitleIds,
  tenureType,
}) => {
  const preparedData = {
    additional_info: additionalInfo,
    leasehold_additional_info: leaseholdAdditionalInfo,
    address: address,
    client_ids: clientIds,
    occupier_ids: occupierIds,
    pass_statements_to_broker: passStatementsToBroker,
    persons_to_be_added_to_title_ids: personsToBeAddedToTitleIds,
    persons_to_be_removed_from_title_ids: personsToBeRemovedFromTitleIds,
    tenure_type: tenureType,
  };

  if (
    currentLenderAccountNumber ||
    currentLenderAmountOwed ||
    currentLenderMakingOverpayment ||
    currentLenderOverpaymentAmount ||
    currentLenderName ||
    currentLenderBankStatementDocuments
  ) {
    const isItMakingOverpayment =
      (typeof currentLenderMakingOverpayment === 'boolean' && currentLenderMakingOverpayment) ||
      currentLenderMakingOverpayment === 'true';

    const isItAdditionalBorrowing =
      (typeof currentLenderAdditionalBorrowing === 'boolean' && currentLenderAdditionalBorrowing) ||
      currentLenderAdditionalBorrowing === 'true';

    preparedData.current_lender = {
      name: currentLenderName,
      account_number: currentLenderAccountNumber,
      amount_owed: currentLenderAmountOwed,
      making_overpayment: currentLenderMakingOverpayment,
      overpayment_amount: isItMakingOverpayment ? currentLenderOverpaymentAmount : null,
      bank_statement_documents: isItMakingOverpayment ? currentLenderBankStatementDocuments : null,
      additional_borrowing: currentLenderAdditionalBorrowing,
      additional_borrowing_amount: isItAdditionalBorrowing ? currentLenderAdditionalBorrowingAmount : null,
    };
  }

  if (additionalLenderName === null) {
    preparedData.additional_lender = null;
    preparedData.additional_lender_keep = null;
  } else if (
    additionalLenderAccountNumber ||
    additionalLenderAmountOwed ||
    additionalLenderKeep ||
    additionalLenderName ||
    additionalLenderHelpToBuyCharge ||
    additionalLenderHelpToBuyChargePayingOff ||
    additionalLenderTargetHcaToPayOff ||
    additionalLenderTargetHcaToRemortgage
  ) {
    preparedData.additional_lender = {
      name: additionalLenderName,
      account_number: additionalLenderAccountNumber,
      amount_owed: additionalLenderAmountOwed,
      help_to_buy_charge: additionalLenderHelpToBuyCharge,
      help_to_buy_charge_paying_off: additionalLenderHelpToBuyCharge ? additionalLenderHelpToBuyChargePayingOff : null,
      target_hca_to_pay_off:
        additionalLenderHelpToBuyCharge && additionalLenderHelpToBuyChargePayingOff
          ? additionalLenderTargetHcaToPayOff
          : null,
      target_hca_to_remortgage:
        additionalLenderHelpToBuyCharge && additionalLenderHelpToBuyChargePayingOff === false
          ? additionalLenderTargetHcaToRemortgage
          : null,
    };
    preparedData.additional_lender_keep = additionalLenderKeep;
  }

  if (newLenderAmountOwed || newLenderName) {
    preparedData.new_lender = {
      name: newLenderName,
      amount_owed: newLenderAmountOwed,
    };
  }

  if (bankName || bankAccountName || bankAccountNumber || bankSortCode || bankBacsOrChaps) {
    if (bankSortCode) {
      bankSortCode = String(bankSortCode).replace(/-/g, '');
    }

    preparedData.bank_details = {
      bank_name: bankName,
      account_name: bankAccountName,
      account_number: bankAccountNumber,
      sort_code: bankSortCode,
      bacs_or_chaps: bankBacsOrChaps,
    };
  }

  if (
    earlyRepaymentCharge ||
    earlyRepaymentChargeEndDate ||
    earlyRepaymentChargeExists ||
    earlyRepaymentChargePostponeCompletion ||
    earlyRepaymentChargeUnknown
  ) {
    // Filter out invalid combinations of ERC data
    const hasErc =
      earlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_KNOWN ||
      earlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_UNKNOWN;
    const canHaveErcPenalty = hasErc && !earlyRepaymentChargeUnknown;
    const canErcPenaltyBeUnknown = hasErc && (earlyRepaymentCharge == null || earlyRepaymentCharge.trim() == '');
    const canEnterErcEndDate = earlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_KNOWN;

    earlyRepaymentCharge = canHaveErcPenalty && earlyRepaymentCharge !== undefined ? earlyRepaymentCharge : null;
    earlyRepaymentChargeUnknown =
      canErcPenaltyBeUnknown && earlyRepaymentChargeUnknown !== undefined ? earlyRepaymentChargeUnknown : null;
    earlyRepaymentChargeEndDate =
      canEnterErcEndDate && earlyRepaymentChargeEndDate !== undefined ? earlyRepaymentChargeEndDate : null;
    earlyRepaymentChargePostponeCompletion =
      hasErc && earlyRepaymentChargePostponeCompletion !== undefined ? earlyRepaymentChargePostponeCompletion : null;

    preparedData.early_repayment_charge = {
      erc_exists: earlyRepaymentChargeExists,
      erc_penalty: earlyRepaymentCharge,
      erc_penalty_unknown: earlyRepaymentChargeUnknown,
      erc_end_date: earlyRepaymentChargeEndDate,
      erc_postpone_completion: earlyRepaymentChargePostponeCompletion,
    };
  }

  if (
    additionalLenderEarlyRepaymentCharge ||
    additionalLenderEarlyRepaymentChargeEndDate ||
    additionalLenderEarlyRepaymentChargeExists ||
    additionalLenderEarlyRepaymentChargePostponeCompletion ||
    additionalLenderEarlyRepaymentChargeUnknown
  ) {
    // Filter out invalid combinations of ERC data
    const additionalLenderHasErc =
      additionalLenderEarlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_KNOWN ||
      additionalLenderEarlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_UNKNOWN;
    const additionalLenderCanHaveErcPenalty = additionalLenderHasErc && !additionalLenderEarlyRepaymentChargeUnknown;
    const additionalLenderCanErcPenaltyBeUnknown =
      additionalLenderHasErc &&
      (additionalLenderEarlyRepaymentCharge == null || additionalLenderEarlyRepaymentCharge.trim() == '');
    const additionalLenderCanEnterErcEndDate =
      additionalLenderEarlyRepaymentChargeExists === ERC_EXISTS_OPTION_YES_KNOWN;

    additionalLenderEarlyRepaymentCharge =
      additionalLenderCanHaveErcPenalty && additionalLenderEarlyRepaymentCharge !== undefined
        ? additionalLenderEarlyRepaymentCharge
        : null;
    additionalLenderEarlyRepaymentChargeUnknown =
      additionalLenderCanErcPenaltyBeUnknown && additionalLenderEarlyRepaymentChargeUnknown !== undefined
        ? additionalLenderEarlyRepaymentChargeUnknown
        : null;
    additionalLenderEarlyRepaymentChargeEndDate =
      additionalLenderCanEnterErcEndDate && additionalLenderEarlyRepaymentChargeEndDate !== undefined
        ? additionalLenderEarlyRepaymentChargeEndDate
        : null;
    additionalLenderEarlyRepaymentChargePostponeCompletion =
      additionalLenderHasErc && additionalLenderEarlyRepaymentChargePostponeCompletion !== undefined
        ? additionalLenderEarlyRepaymentChargePostponeCompletion
        : null;

    preparedData.early_repayment_charge_additional_lender = {
      erc_exists: additionalLenderEarlyRepaymentChargeExists,
      erc_penalty: additionalLenderEarlyRepaymentCharge,
      erc_penalty_unknown: additionalLenderEarlyRepaymentChargeUnknown,
      erc_end_date: additionalLenderEarlyRepaymentChargeEndDate,
      erc_postpone_completion: additionalLenderEarlyRepaymentChargePostponeCompletion,
    };
  }

  // Remove all undefined from payload
  const remortgageData = Object.entries(preparedData).reduce(
    (a, [k, v]) => (v === undefined ? a : ((a[k] = v), a)),
    {},
  );

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/remortgages/${id}`, remortgageData);
};
