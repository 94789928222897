import axios from 'axios';

export default ({ persons }) => {
  return new Promise((resolve, reject) => {
    Promise.all(
      persons.map((item) => {
        const profileRemortgagor = {
          profile_remortgagor: {
            occupation: item.occupation,
            bankrupt: item.bankrupt,
            name_changed: item.nameChanged,
            availability: item.availability,
            relationship: item.relationship,
            politically_exposed_person: item.politicallyExposedPerson,
          },
        };

        profileRemortgagor.profile_remortgagor.relationship_other =
          item.relationship === 'Other' ? item.relationshipOther : null;

        if (item.bankrupt === true) {
          profileRemortgagor.profile_remortgagor.bankrupt_date = item.bankruptDate;
        } else if (item.bankrupt === false) {
          profileRemortgagor.profile_remortgagor.bankrupt_date = null;
        }

        if (item.nameChanged === true) {
          profileRemortgagor.profile_remortgagor.name_changed_details = item.nameChangedDetails;
        } else if (item.nameChanged === false) {
          profileRemortgagor.profile_remortgagor.name_changed_details = null;
        }

        let ni_number = item.niNumber !== null ? item.niNumber.replace(/[ -]/g, '') : '';

        let no_middle_name_confirmed = item.middle_name?.length > 0 ? false : item.noMiddleNameConfirmed;

        let personData = {
          title: item.title,
          first_name: item.firstName,
          middle_name: item.middleName,
          no_middle_name_confirmed: no_middle_name_confirmed,
          last_name: item.lastName,
          previous_name: item.previousName,
          dob: item.dob,
          gender: item.gender,
          nationality: item.nationality,
          correspondence_address: item.correspondenceAddress,
          phone_home: item.phoneHome,
          phone_mobile: item.phoneMobile,
          phone_work: item.phoneWork,
          email: item.email,
          profile_identification: {
            ni_number: ni_number,
          },
          ...profileRemortgagor,
        };

        // Remove all undefined from payload
        personData = Object.entries(personData).reduce((a, [k, v]) => (v === undefined ? a : ((a[k] = v), a)), {});

        if (item.hasOwnProperty('personId')) {
          return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/persons/${item.personId}`, personData);
        } else {
          return axios.post(`${process.env.VUE_APP_API_ENDPOINT}/persons`, personData);
        }
      }),
    )
      .then((resultData) => {
        resolve(resultData);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};
