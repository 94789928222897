<script>
import CurrencyInput from '@/components/forms/inputGroups/CurrencyInput';
import DatePicker from '@/components/DatePicker';
import InlineFileUploader from '@/components/fileUploader/InlineFileUploader';
import transferOfEquity from '@/functions/supportingDocuments/transferOfEquity';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const enclosedOrToFollow = {
  ETF_ENCLOSED: 'Attached',
  ETF_TO_FOLLOW: 'To follow',
};

const titles = [
  { value: null, text: 'Please choose', disabled: true },
  'Mr',
  'Mrs',
  'Miss',
  'Ms',
  'Dr',
  'Sir',
  'Mx',
  'Rev',
  'Lord',
  'Lady',
  'Prof',
  'NA',
];

const rawPerson = {
  title: null,
  firstName: '',
  middleName: '',
  noMiddleNameConfirmed: false,
  lastName: '',
  dob: null,
  phoneMobile: null,
  email: null,
  toeAmount: null,
  toeMarriedOrCivilPartnershipWithTitlePerson: null,
  toeOwnOtherProperty: null,
  toeIsPrimaryResidence: null,
  toeAnyMoneyPaidForTransfer: null,
  toeTransferByCourtOrder: null,
  toeTransferByCourtOrderDocuments: null,
};

export default {
  name: 'TransferOfEquityPersonEditor',
  props: ['toePersons', 'displayToeAmount', 'showEditInProgressWarning'],
  components: { CurrencyInput, DatePicker, InlineFileUploader },
  data() {
    return {
      editing$: false, // this is a 'private' backing value
      editingIndex: null,
      yesOrNo,
      enclosedOrToFollow,
      transferOfEquity,
      titles,
      rawPerson,
      localData: {
        personId: null,
        title: this.title,
        firstName: '',
        middleName: '',
        noMiddleNameConfirmed: false,
        lastName: '',
        phoneMobile: null,
        dob: null,
        email: null,
        toeAmount: null,
        toeMarriedOrCivilPartnershipWithTitlePerson: null,
        toeOwnOtherProperty: null,
        toeIsPrimaryResidence: null,
        toeAnyMoneyPaidForTransfer: null,
        toeTransferByCourtOrder: null,
        toeTransferByCourtOrderDocuments: null,
      },
      invalid: false,
      emailIsRequired: true,
      phoneMobileIsRequired: true,
    };
  },
  computed: {
    editing: {
      get() {
        return this.editing$;
      },
      set(value) {
        this.editing$ = value;
        this.$emit('setEditing', value);
      },
    },
    middleNameSet: function () {
      if (!this.localData) {
        return false;
      }

      return this.localData?.middleName?.length > 0;
    },
    showToeAmountField: function () {
      const value = this.localData.toeAnyMoneyPaidForTransfer;
      if (value && typeof value === 'boolean') {
        return value;
      }
      return value === 'true';
    },
    showCopyOfCourtOrderDocuments: function () {
      const value = this.localData.toeTransferByCourtOrder;
      if (value && typeof value === 'boolean') {
        return value;
      }
      return value === 'true';
    },
  },
  methods: {
    async addPerson() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.invalid = true;
        return false; // If the return result is false, tab switch is restricted
      }

      this.$emit('addPerson', {
        index: this.editingIndex,
        value: { ...this.localData },
      });

      this.editing = false;
      this.editingIndex = null;
      this.localData = { ...rawPerson };
    },
    editPerson(index) {
      this.localData = { ...this.toePersons[index] };
      this.editing = true;
      this.editingIndex = index;
    },
    cancelEditing() {
      this.editing = false;
      this.editingIndex = null;
      this.localData = { ...rawPerson };
    },
    enableEditing() {
      this.editing = true;
    },
    async deletePerson(id) {
      this.$emit('deletePerson', id);
    },
    async validatePage() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid || this.editing) {
        this.invalid = true;
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }

      this.invalid = false;
      this.saving = false;
      return true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      this.emailIsRequired = !(
        this.localData?.phoneMobile !== '' &&
        this.localData?.phoneMobile !== null &&
        typeof this.localData?.phoneMobile !== 'undefined'
      );
      this.phoneMobileIsRequired = !(
        this.localData?.email !== '' &&
        this.localData?.email !== null &&
        typeof this.localData?.email !== 'undefined'
      );

      return dirty || validated ? valid : null;
    },
  },
  mounted() {
    this.localData = { ...rawPerson };
  },
};
</script>

<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <div class="row pb-2">
        <div class="col-md-9">
          <h5><slot name="title-text"></slot></h5>
        </div>
        <div class="col-md-3 d-flex justify-content-end">
          <button :disabled="invalid" @click="enableEditing" class="btn btn-add" v-if="!editing" />
        </div>
      </div>
      <div class="border-top my-3"></div>
      <div v-if="editing">
        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Title </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="title" rules="required" v-slot="validationContext">
              <b-form-select
                :options="titles"
                :state="getValidationState(validationContext)"
                class="form-control bg-white"
                id="person_title"
                v-model="localData.title"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="title-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> First Name: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="first name"
              :rules="{ required: true, person_name: 'first name' }"
              v-slot="validationContext"
            >
              <b-form-input
                :state="getValidationState(validationContext)"
                class="form-control"
                id="first-name"
                v-model="localData.firstName"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="first-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label> Middle Name(s): </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="middle name" :rules="{ person_name: 'middle name' }" v-slot="validationContext">
              <b-form-input
                :state="getValidationState(validationContext)"
                :disabled="localData.noMiddleNameConfirmed"
                class="form-control"
                id="middle-name"
                v-model="localData.middleName"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="middle-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2" v-if="!middleNameSet">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <ValidationProvider
              name="middle name confirm"
              :rules="{ required: { allowFalse: false } }"
              v-slot="validationContext"
            >
              <b-form-checkbox
                v-model="localData.noMiddleNameConfirmed"
                type="checkbox"
                id="middle-name-confirm"
                :state="getValidationState(validationContext)"
                :aria-invalid="validationContext.errors[0] !== null"
                >Please confirm that you do not have a middle name.</b-form-checkbox
              >
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Last Name: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="last name"
              :rules="{ required: true, person_name: 'last name' }"
              v-slot="validationContext"
            >
              <b-form-input
                :state="getValidationState(validationContext)"
                class="form-control"
                id="last-name"
                v-model="localData.lastName"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="last-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Date of birth: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <date-picker
              v-model="localData.dob"
              validationFieldName="DOB"
              minimumView="day"
              :minimumAge="18"
              :required="true"
              id="dob"
            />
          </div>
        </div>

        <div v-if="displayToeAmount">
          <div class="my-3"></div>

          <!-- Is there any money being paid for the transfer? -->
          <div class="row pb-2">
            <div class="col-md-9">
              <h5>
                <label class="mandatory"> Is there any money being paid for the transfer? </label>
              </h5>
            </div>
            <div class="col-md-3">
              <ValidationProvider
                name="any money being paid for the transfer"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-select
                  v-model="localData.toeAnyMoneyPaidForTransfer"
                  :options="yesOrNo"
                  id="toe-any-money-paid-for-transfer"
                  class="form-control bg-white"
                  :state="getValidationState(validationContext)"
                ></b-form-select>
                <b-form-invalid-feedback data-error-name="toe-any-money-paid-for-transfer"
                  >{{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
          </div>
          <!-- /end: Is there any money being paid for the transfer? -->

          <div v-if="showToeAmountField">
            <div class="row pb-2">
              <div class="col-md-9">
                <h5>
                  <label class="mandatory"> Amount of money passing between you for the transaction: </label>
                </h5>
              </div>

              <div class="col-md-3">
                <currency-input
                  v-model="localData.toeAmount"
                  validationFieldName="amount of money passed"
                  id="amount-of-money-passed"
                  :required="true"
                ></currency-input>
              </div>
            </div>
          </div>
        </div>

        <!-- Are they married to or in a civil partnership with the person on the title? -->
        <div class="row pb-2">
          <div class="col-md-9">
            <h5>
              <label class="mandatory">
                Are they married to or in a civil partnership with the person on the title?
              </label>
            </h5>
          </div>
          <div class="col-md-3">
            <ValidationProvider
              name="married to or in a civil partnership"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-select
                v-model="localData.toeMarriedOrCivilPartnershipWithTitlePerson"
                :options="yesOrNo"
                id="toe-married-or-civil-partnership-with-title-person"
                class="form-control bg-white"
                :state="getValidationState(validationContext)"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="toe-married-or-civil-partnership-with-title-person"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
        <!-- /end: Are they married to or in a civil partnership with the person on the title? -->

        <!-- Do they own any other property? -->
        <div class="row pb-2">
          <div class="col-md-9">
            <h5>
              <label class="mandatory"> Do they own any other property? </label>
            </h5>
          </div>
          <div class="col-md-3">
            <ValidationProvider name="own any other property" :rules="{ required: true }" v-slot="validationContext">
              <b-form-select
                v-model="localData.toeOwnOtherProperty"
                :options="yesOrNo"
                id="toe-own-other-property"
                class="form-control bg-white"
                :state="getValidationState(validationContext)"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="toe-own-other-property"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
        <!-- /end: Do they own any other property? -->

        <!-- Will this property be their primary residence? -->
        <div class="row pb-2">
          <div class="col-md-9">
            <h5>
              <label class="mandatory"> Will this property be their primary residence? </label>
            </h5>
          </div>
          <div class="col-md-3">
            <ValidationProvider
              name="property be their primary residence"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-select
                v-model="localData.toeIsPrimaryResidence"
                :options="yesOrNo"
                id="toe-is-primary-residence"
                class="form-control bg-white"
                :state="getValidationState(validationContext)"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="toe-is-primary-residence"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
        <!-- /end: Will this property be their primary residence? -->

        <!-- Is the transfer as a result of a court order? -->
        <div class="row pb-2">
          <div class="col-md-9">
            <h5>
              <label class="mandatory"> Is the transfer as a result of a court order? </label>
            </h5>
          </div>
          <div class="col-md-3">
            <ValidationProvider
              name="transfer as a result of a court order"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-select
                v-model="localData.toeTransferByCourtOrder"
                :options="yesOrNo"
                id="toe-transfer-by-court-order"
                class="form-control bg-white"
                :state="getValidationState(validationContext)"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="toe-transfer-by-court-order"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
        <!-- /end: Is the transfer as a result of a court order? -->

        <!-- Upload court order document -->
        <div v-if="showCopyOfCourtOrderDocuments">
          <div class="row pb-2">
            <div class="col-md-9">
              <h5>
                <label class="mandatory"> Please supply a copy for court order documents? </label>
              </h5>
            </div>
            <div class="col-md-3">
              <ValidationProvider name="court order documents" :rules="{ required: true }" v-slot="validationContext">
                <b-form-select
                  v-model="localData.toeTransferByCourtOrderDocuments"
                  :options="enclosedOrToFollow"
                  id="toe-transfer-by-court-order-documents"
                  class="form-control bg-white"
                  :state="getValidationState(validationContext)"
                ></b-form-select>
                <b-form-invalid-feedback data-error-name="toe-transfer-by-court-order-documents"
                  >{{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!-- /end: Upload court order document -->

        <inline-file-uploader
          v-if="localData.toeTransferByCourtOrderDocuments === 'ETF_ENCLOSED'"
          :requiredDocument="transferOfEquity.toeCourtOrderDocuments"
        />

        <div class="my-3"></div>

        <div class="row pb-2">
          <div class="col-md-12">
            <h5>Please provide at least one form of contact:</h5>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label :class="emailIsRequired ? 'mandatory' : ''"> Email Address: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="email"
              :rules="{ required: emailIsRequired, email: true }"
              v-slot="validationContext"
            >
              <b-form-input
                :state="getValidationState(validationContext)"
                class="form-control"
                id="email"
                type="email"
                v-model="localData.email"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="email-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label for="phone-mobile" :class="phoneMobileIsRequired ? 'mandatory' : ''"> Mobile Telephone: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="mobile number"
              :rules="{ required: phoneMobileIsRequired, mobile_number: true }"
              v-slot="validationContext"
            >
              <b-form-input
                v-model="localData.phoneMobile"
                id="phone-mobile"
                class="form-control"
                :state="getValidationState(validationContext)"
                type="tel"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="phone-mobile-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="row pb-1 justify-content-end">
        <div class="col-md-3">
          <button @click="cancelEditing" v-if="editing" class="btn btn-cancel btn-block mb-1" />
        </div>
        <div class="col-md-3">
          <button v-if="editing" @click="addPerson" class="btn btn-save btn-block" />
        </div>
      </div>

      <div class="row pb-2" v-if="showEditInProgressWarning && editing">
        <div class="col-md-12">
          <h6 class="save-warning">Please save or discard any changes before continuing.</h6>
        </div>
      </div>

      <div>
        <b-list-group v-if="!editing && toePersons.length !== 0" class="p-0 mt-1" :flush="true">
          <b-list-group-item variant="light">
            <div class="col-6 d-inline-block col-md-4">Name</div>
            <div class="col-6 d-inline-block col-md-8">Date of birth</div>
          </b-list-group-item>
          <b-list-group-item
            v-for="(item, index) in toePersons"
            :key="index"
            :class="{ 'bg-teal': editing && item.personId === editingPersonId }"
            class="py-2 px-0"
            variant="light"
          >
            <div class="col-6 d-inline-block col-md-4">
              {{ item.firstName }} {{ item.middleName }} {{ item.lastName }}
            </div>
            <div class="col-6 d-inline-block col-md-4">
              {{ item.dob | formatDate }}
            </div>
            <div class="d-lg-inline-block col-lg-4">
              <div class="d-flex justify-content-center justify-content-lg-end">
                <button class="btn btn-edit mr-1" @click="editPerson(index)"></button>
                <button class="btn btn-remove" @click="deletePerson(item.personId)"></button>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
        <span class="text-muted" v-if="!editing && toePersons.length === 0">No persons added</span>
      </div>
    </ValidationObserver>
  </div>
</template>
