<script>
export default {
  name: 'CurrencyInput',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    validationFieldName: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: '£',
    },
  },
  data() {
    return {
      priceValue: this.value,
    };
  },
  methods: {
    triggerUpdate(value) {
      this.$emit('input', Number(value));
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div>
    <validation-provider
      :name="validationFieldName"
      :rules="{ required: required, currency: true }"
      v-slot="validationContext"
    >
      <b-input-group :prepend="label">
        <b-form-input
          v-model="priceValue"
          :id="id"
          placeholder="0.00"
          aria-describedby="price-error"
          type="number"
          class="rounded"
          step="0.01"
          min="0"
          @change="triggerUpdate"
          :state="getValidationState(validationContext)"
        ></b-form-input>
        <b-form-invalid-feedback data-error-name="price-error"
          >{{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-input-group>
    </validation-provider>
  </div>
</template>
