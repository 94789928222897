export default (persons) => {
  return new Promise((resolve, reject) => {
    let errors = [];
    persons.map((item) => {
      let requiredFields = {
        dob: 'DOB',
        firstName: 'First Name',
        lastName: 'Last Name',
        title: 'Title',
        toeAnyMoneyPaidForTransfer: 'Is there any money being paid for the transfer?',
        toeIsPrimaryResidence: 'Will this property be their primary residence?',
        toeMarriedOrCivilPartnershipWithTitlePerson:
          'Are they married to or in a civil partnership with the person on the title?',
        toeOwnOtherProperty: 'Do they own any other property?',
        toeTransferByCourtOrder: 'Is the transfer as a result of a court order?',
      };

      const missingFields = [];
      Object.keys(requiredFields).forEach(function (field) {
        if (item[field] === null || item[field] === '') {
          missingFields.push(requiredFields[field]);
        }
      });

      if (!item.noMiddleNameConfirmed && !item.middleName) {
        missingFields.push('Middle name confirmation');
      }

      if (!item.email && !item.phoneMobile) {
        missingFields.push('Please provide at least one form of contact: Email or Mobile Phone.');
      }

      const isItToeAnyMoneyPaidForTransfer =
        (typeof item.toeAnyMoneyPaidForTransfer === 'boolean' && item.toeAnyMoneyPaidForTransfer) ||
        item.toeAnyMoneyPaidForTransfer === 'true';

      if (isItToeAnyMoneyPaidForTransfer && !item.toeAmount) {
        missingFields.push('Please provide amount of money passing between you for the transaction.');
      }

      const isItToeTransferByCourtOrder =
        (typeof item.toeTransferByCourtOrder === 'boolean' && item.toeTransferByCourtOrder) ||
        item.toeTransferByCourtOrder === 'true';

      if (isItToeTransferByCourtOrder && !item.toeTransferByCourtOrderDocuments) {
        missingFields.push('Please supply a copy for court order documents?');
      }

      if (missingFields.length > 0) {
        errors.push(
          `Please check ${
            item.firstName !== null && item.lastName !== null ? `${item.firstName} ${item.lastName}'s` : 'all client'
          } information and ensure that any required fields are complete.`,
        );
      }
    });

    if (errors.length > 0) {
      reject(errors);
    }

    resolve(true);
  });
};
