<script>
export default {
  name: 'PoliticallyExposedPersonInfo',
};
</script>

<template>
  <div class="text-center">
    <i class="icon-info3 icon-2x text-info border-info border-3 rounded-round p-3 mb-3 mt-1"></i>
    <h3>POLITICALLY EXPOSED PERSON</h3>
    <p>
      A “politically exposed person” or “PEP” is an individual that holds a prominent public position or function. Due
      to their role, they may be more susceptible to bribery, corruption or other money laundering offences, as a result
      of this they are of high risk to financial institutions and Designated Non-Financial Businesses and Professions
      (DNFBPs).
    </p>
  </div>
</template>
