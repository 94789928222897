<script>
import patchRemortgagePersons from '@/api/persons/patchRemortgagePersons';
import PersonQuestionnaire from '@/components/forms/remortgageClientQuestionnaire/PersonQuestionnaire';
import { mapState, mapMutations } from 'vuex';
import * as personsMutations from '@/store/persons/mutations';
import * as remortgageMutations from '@/store/remortgage/mutations';
import patchRemortgage from '@/api/remortgages/patchRemortgage';
import Loader from '@/components/Loader';
import Modal from '@/components/Modal';
import validatePersons from '@/functions/validatePersons';

const rawPerson = {
  title: null,
  firstName: '',
  middleName: '',
  lastName: '',
  previousName: '',
  dob: null,
  gender: null,
  nationality: null,
  correspondenceAddress: {
    organisation_name: '',
    premise: '',
    thoroughfare: '',
    dependent_locality: '',
    locality: '',
    subAdministrativeArea: '',
    administrative_area: '',
    postal_code: '',
  },
  previousAddresses: [],
  phoneHome: '',
  phoneMobile: '',
  phoneWork: '',
  email: '',
  occupation: '',
  niNumber: '',
  nameChanged: null,
  nameChangedDetails: '',
  bankrupt: null,
  bankruptDate: null,
  availability: '',
};

export default {
  name: 'RemortgageAboutYou',
  components: { PersonQuestionnaire, Loader, Modal },
  data() {
    return {
      rawPerson,
      lockedButtons: false,
      editing: false,
      editingPersonId: null,
      deletionPersonId: null,
      newPerson: null,
      invalid: false,
      saving: false,
      showDeletionWarning: false,
      showValidationError: null,
    };
  },
  methods: {
    ...mapMutations([
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_SET}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_REMOVE}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_ADD}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_ADD_ALL}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_DETAILS_UPDATED}`,
      `${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CLIENT_IDS}`,
    ]),
    async validatePage() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid || this.editing || this.s_persons.length === 0) {
        this.invalid = true;
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.invalid = false;
      this.saving = false;

      return true;
    },
    async validatePersonsPayload() {
      return await validatePersons(this.s_persons)
        .then((response) => {
          this.showValidationError = null;
          return new Promise((resolve) => {
            resolve(response);
          });
        })
        .catch((error) => {
          this.showValidationError = error;
          return new Promise((reject) => {
            reject(error);
          });
        });
    },
    editPerson(id) {
      this.$emit('personAddEditMode', true);
      this.showValidationError = null;
      this.editing = true;
      this.editingPersonId = id;
    },
    addNewPerson() {
      this.$emit('personAddEditMode', true);
      this.showValidationError = null;
      this.editing = true;
      this.newPerson = this.rawPerson;
    },
    requestPersonDeletion(id) {
      // We need to ensure that person should be deleted
      this.deletionPersonId = id;
      this.showDeletionWarning = true;
    },
    deletionModalResponse(value) {
      if (value) {
        this.deletePerson(this.deletionPersonId);
      }
      this.deletionPersonId = null;
      this.showDeletionWarning = false;
    },
    async deletePerson(id) {
      this.showValidationError = null;
      await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_REMOVE}`](id);
      this.saving = true;
      const outstandingClientIds = this.s_persons.map((item) => item.personId);
      this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CLIENT_IDS}`](
        outstandingClientIds,
      );
      await patchRemortgage({
        id: this.s_remortgage.remortgageId,
        clientIds: outstandingClientIds,
      });
      this.saving = false;
    },
    cancelEditing() {
      this.$emit('personAddEditMode', false);
      this.editingPersonId = null;
      this.editing = false;
      this.newPerson = null;
    },
  },
  computed: {
    ...mapState({
      s_persons: (state) => state.persons.clients,
      s_remortgage: (state) => state.remortgage,
      s_clientDetailsUpdated: (state) => state.persons.clientDetailsUpdated,
    }),
    clientDetailsUpdated: {
      get() {
        return this.s_clientDetailsUpdated;
      },
    },
    formPerson: {
      get() {
        return this.s_persons.find((item) => item.personId === this.editingPersonId);
      },
      async set(value) {
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_SET}`]({
          index: this.personIndex,
          value,
        });
        const personValue = [];
        personValue.push(value);
        this.editingPersonId = null;
        this.editing = false;
        this.$emit('personAddEditMode', false);
        this.saving = true;
        await patchRemortgagePersons({ persons: personValue });
        // force review page data rerender
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_DETAILS_UPDATED}`](true);
        this.saving = false;
      },
    },
    newPersonModel: {
      get() {
        return { ...this.newPerson };
      },
      async set(value) {
        const existingClientIds = this.s_persons.map((item) => item.personId); // Save current client person ids
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_ADD}`](value); // Add new client without person Id into store

        this.saving = true;
        this.editing = false;
        this.newPerson = null;
        this.$emit('personAddEditMode', false);

        const personValue = [];
        personValue.push(value);
        const newPersonsData = await patchRemortgagePersons({
          // Patch new client data to get new person id
          persons: personValue,
        });

        const newPersonId = newPersonsData.map((item) => item.data.Id); // Extract person Ids

        this.s_persons.forEach((client) => {
          // Update new client with received person id
          if (typeof client.personId === 'undefined') {
            client.personId = newPersonId[0];
          }
        });

        const allRemortgageClientIds = existingClientIds.concat(newPersonId);

        this[`${remortgageMutations.REMORTGAGE_PREFIX}${remortgageMutations.REMORTGAGE_SET_CLIENT_IDS}`](
          allRemortgageClientIds,
        );
        let personsToAdd = this.s_persons;
        personsToAdd.forEach((person) => {
          this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_ADD}`](person); // Replace all persons
        });

        await patchRemortgage({
          id: this.s_remortgage.remortgageId,
          clientIds: allRemortgageClientIds,
        });
        this.saving = false;
      },
    },
    personIndex: function () {
      return this.s_persons.findIndex((item) => item.personId === this.editingPersonId);
    },
  },
};
</script>
<template>
  <loader v-if="saving && showValidationError !== null && !editing"></loader>
  <div v-else>
    <div class="row">
      <div class="col-7">
        <h2>About You</h2>
      </div>
      <div class="col-5 text-center text-sm-right">
        <button
          v-if="!editing && s_persons.length < 4"
          class="btn btn-sm d-block w-100 btn-add-additional-contact"
          @click="addNewPerson"
        ></button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table" v-if="!editing" :key="clientDetailsUpdated">
        <thead>
          <tr>
            <th>Name</th>
            <th class="d-none d-md-table-cell">Email</th>
            <th class="d-none d-sm-table-cell">Phone No</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tr v-if="s_persons.length === 0">
          <td colspan="3" :class="invalid ? 'text-danger' : ''">Please add new information about you.</td>
        </tr>
        <tr
          v-for="(item, index) in s_persons"
          :key="index"
          :class="{ 'bg-teal': editing && item.personId === editingPersonId }"
        >
          <td class="font-size-lg">{{ item.firstName }} {{ item.middleName }} {{ item.lastName }}</td>

          <td class="d-none d-md-table-cell font-size-lg">{{ item.email }}</td>
          <td class="d-none d-sm-table-cell font-size-lg">
            {{ item.phoneMobile }}
          </td>
          <td class="text-right">
            <div v-if="lockedButtons" class="d-flex justify-content-center">
              <loader />
            </div>
            <button class="btn btn-edit mr-1" @click="editPerson(item.personId)"></button>
            <button
              class="btn btn-remove"
              v-if="!item.cognitoUserId"
              @click="requestPersonDeletion(item.personId)"
            ></button>
          </td>
        </tr>
      </table>
    </div>

    <ValidationObserver ref="observer" v-slot="{ errors }">
      <div v-if="newPerson">
        <div class="border-top my-3"></div>
        <person-questionnaire
          v-model="newPersonModel"
          ref="personQuestionnaire"
          @cancelUpdate="cancelEditing"
        ></person-questionnaire>
      </div>

      <div v-if="formPerson">
        <div class="border-top my-3"></div>
        <person-questionnaire v-model="formPerson" @cancelUpdate="cancelEditing"></person-questionnaire>
      </div>
    </ValidationObserver>

    <div v-if="!editing" class="mt-2">
      <p class="text-center">
        Please ensure that you enter details as they appear on your ID documents.<br /><br />

        <b>Please note</b> in order to complete our identity verification process, we will require an email address AND
        mobile phone number for each person listed below so they can be contacted. Please ensure these details are added
        before moving on to the next page.
      </p>
    </div>

    <div v-if="showValidationError !== null && !editing">
      <p class="text-danger mt-1 mb-1" v-for="(item, index) in this.showValidationError" :key="index">
        {{ item }}
      </p>
    </div>

    <modal
      v-if="showDeletionWarning"
      closeButtonText="Cancel"
      proceedButtonText="Delete"
      @proceed="deletionModalResponse"
    >
      <template slot="header"><h3 class="text-danger-400">Delete Warning</h3></template>

      <div class="text-center">
        <i class="icon-warning2 icon-2x text-danger-400 border-danger-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
        <h3>Are you sure you want to delete this person?</h3>
      </div>
    </modal>
  </div>
</template>
