export default (persons) => {
  return new Promise((resolve, reject) => {
    let errors = [];
    let personEmails = persons.map((v) => v.email).filter((v) => v !== '' && v !== null && typeof v !== 'undefined');
    let phoneMobile = persons
      .map((v) => v.phoneMobile)
      .filter((v) => v !== '' && v !== null && typeof v !== 'undefined');
    let phoneHome = persons.map((v) => v.phoneHome).filter((v) => v !== '' && v !== null && typeof v !== 'undefined');

    persons.map((item) => {
      let requiredFields = {
        title: 'Title',
        firstName: 'First Name',
        lastName: 'Last Name',
        dob: 'DOB',
        gender: 'Gender',
        nationality: 'Nationality',
        email: 'Email',
        niNumber: 'NI Number',
      };

      const missingFields = [];
      Object.keys(requiredFields).forEach(function (field) {
        if (item[field] === null || item[field] === '') {
          missingFields.push(requiredFields[field]);
        }
      });

      if (!item.noMiddleNameConfirmed && !item.middleName) {
        missingFields.push('Middle name confirmation');
      }

      if (missingFields.length > 0) {
        errors.push(
          `Please check ${
            item.firstName !== null && item.lastName !== null ? `${item.firstName} ${item.lastName}'s` : 'all client'
          } information and ensure that any required fields are complete.`,
        );
      }

      // Check email duplicates
      let matchedPersonEmailsQty = personEmails.filter((v) => v === item.email).length;
      if (matchedPersonEmailsQty > 1) {
        errors.push(
          `Please check ${
            item.firstName !== null && item.lastName !== null
              ? `${item.firstName} ${item.lastName}'s email`
              : 'all client emails'
          } as this ${item.email} has been used for more then one person.`,
        );
      }

      // Check mobile phone duplicates
      let matchedPhoneMobileQty = phoneMobile.filter((v) => v === item.phoneMobile).length;
      if (matchedPhoneMobileQty > 1) {
        errors.push(
          `Please check ${
            item.firstName !== null && item.lastName !== null
              ? `${item.firstName} ${item.lastName}'s mobile phone number`
              : 'all client mobile phone numbers'
          } as this number: ${item.phoneMobile} has been used for more then one person.`,
        );
      }

      // Check home phone duplicates only if phone mobile is empty
      let matchedPhoneHomeQty = phoneHome.filter((v) => v === item.phoneHome).length;
      if (matchedPhoneHomeQty > 1 && item.phoneMobile === '') {
        errors.push(
          `Please check ${
            item.firstName !== null && item.lastName !== null
              ? `${item.firstName} ${item.lastName}'s home phone number`
              : 'all client home phone numbers'
          } as this number: ${item.phoneHome} has been used for more then one person.`,
        );
      }
    });

    if (errors.length > 0) {
      reject(errors);
    }

    resolve(true);
  });
};
