const titles = [
  { value: null, text: 'Please choose', disabled: true },
  'Mr',
  'Mrs',
  'Miss',
  'Ms',
  'Dr',
  'Sir',
  'Other',
];

export default titles;
