const nationalities = {
  British: 'British',
  English: 'English',
  Scottish: 'Scottish',
  Welsh: 'Welsh',
  NorthernIrish: 'Northern Irish',
  Afghan: 'Afghan',
  Albanian: 'Albanian',
  Algerian: 'Algerian',
  American: 'American',
  Andorran: 'Andorran',
  Angolan: 'Angolan',
  Anguillan: 'Anguillan',
  Argentine: 'Argentine',
  Armenian: 'Armenian',
  Australian: 'Australian',
  Austrian: 'Austrian',
  Azerbaijani: 'Azerbaijani',
  Bahamian: 'Bahamian',
  Bahraini: 'Bahraini',
  Bangladeshi: 'Bangladeshi',
  Barbadian: 'Barbadian',
  Belarusian: 'Belarusian',
  Belgian: 'Belgian',
  Belizean: 'Belizean',
  Beninese: 'Beninese',
  Bermudian: 'Bermudian',
  Bhutanese: 'Bhutanese',
  Bolivian: 'Bolivian',
  Botswanan: 'Botswanan',
  Brazilian: 'Brazilian',
  BritishVirginIslander: 'British Virgin Islander',
  Bruneian: 'Bruneian',
  Bulgarian: 'Bulgarian',
  Burkinan: 'Burkinan',
  Burmese: 'Burmese',
  Burundian: 'Burundian',
  Cambodian: 'Cambodian',
  Cameroonian: 'Cameroonian',
  Canadian: 'Canadian',
  CapeVerdean: 'Cape Verdean',
  CaymanIslander: 'Cayman Islander',
  CentralAfrican: 'Central African',
  Chadian: 'Chadian',
  Chilean: 'Chilean',
  Chinese: 'Chinese',
  CitizenOfAntiguaandBarbuda: 'Citizen of Antigua and Barbuda',
  CitizenOfBosniaandHerzegovina: 'Citizen of Bosnia and Herzegovina',
  CitizenOfGuineaBissau: 'Citizen of Guinea-Bissau',
  CitizenOfKiribati: 'Citizen of Kiribati',
  CitizenOfSeychelles: 'Citizen of Seychelles',
  CitizenOftheDominicanRepublic: 'Citizen of the Dominican Republic',
  CitizenOfVanuatu: 'Citizen of Vanuatu',
  Colombian: 'Colombian',
  Comoran: 'Comoran',
  CongoleseCongo: 'Congolese (Congo)',
  CongoleseDRC: 'Congolese (DRC)',
  CookIslander: 'Cook Islander',
  CostaRican: 'Costa Rican',
  Croatian: 'Croatian',
  Cuban: 'Cuban',
  Cymraes: 'Cymraes',
  Cymro: 'Cymro',
  Cypriot: 'Cypriot',
  Czech: 'Czech',
  Danish: 'Danish',
  Djiboutian: 'Djiboutian',
  Dominican: 'Dominican',
  Dutch: 'Dutch',
  EastTimorese: 'East Timorese',
  Ecuadorean: 'Ecuadorean',
  Egyptian: 'Egyptian',
  Emirati: 'Emirati',
  EquatorialGuinean: 'Equatorial Guinean',
  Eritrean: 'Eritrean',
  Estonian: 'Estonian',
  Ethiopian: 'Ethiopian',
  Faroese: 'Faroese',
  Fijian: 'Fijian',
  Filipino: 'Filipino',
  Finnish: 'Finnish',
  French: 'French',
  Gabonese: 'Gabonese',
  Gambian: 'Gambian',
  Georgian: 'Georgian',
  German: 'German',
  Ghanaian: 'Ghanaian',
  Gibraltarian: 'Gibraltarian',
  Greek: 'Greek',
  Greenlandic: 'Greenlandic',
  Grenadian: 'Grenadian',
  Guamanian: 'Guamanian',
  Guatemalan: 'Guatemalan',
  Guinean: 'Guinean',
  Guyanese: 'Guyanese',
  Haitian: 'Haitian',
  Honduran: 'Honduran',
  HongKonger: 'Hong Konger',
  Hungarian: 'Hungarian',
  Icelandic: 'Icelandic',
  Indian: 'Indian',
  Indonesian: 'Indonesian',
  Iranian: 'Iranian',
  Iraqi: 'Iraqi',
  Irish: 'Irish',
  Israeli: 'Israeli',
  Italian: 'Italian',
  Ivorian: 'Ivorian',
  Jamaican: 'Jamaican',
  Japanese: 'Japanese',
  Jordanian: 'Jordanian',
  Kazakh: 'Kazakh',
  Kenyan: 'Kenyan',
  Kittitian: 'Kittitian',
  Kosovan: 'Kosovan',
  Kuwaiti: 'Kuwaiti',
  Kyrgyz: 'Kyrgyz',
  Lao: 'Lao',
  Latvian: 'Latvian',
  Lebanese: 'Lebanese',
  Liberian: 'Liberian',
  Libyan: 'Libyan',
  LiechtensteinCitizen: 'Liechtenstein citizen',
  Lithuanian: 'Lithuanian',
  Luxembourger: 'Luxembourger',
  Macanese: 'Macanese',
  Macedonian: 'Macedonian',
  Malagasy: 'Malagasy',
  Malawian: 'Malawian',
  Malaysian: 'Malaysian',
  Maldivian: 'Maldivian',
  Malian: 'Malian',
  Maltese: 'Maltese',
  Marshallese: 'Marshallese',
  Martiniquais: 'Martiniquais',
  Mauritanian: 'Mauritanian',
  Mauritian: 'Mauritian',
  Mexican: 'Mexican',
  Micronesian: 'Micronesian',
  Moldovan: 'Moldovan',
  Monegasque: 'Monegasque',
  Mongolian: 'Mongolian',
  Montenegrin: 'Montenegrin',
  Montserratian: 'Montserratian',
  Moroccan: 'Moroccan',
  Mosotho: 'Mosotho',
  Mozambican: 'Mozambican',
  Namibian: 'Namibian',
  Nauruan: 'Nauruan',
  Nepalese: 'Nepalese',
  NewZealander: 'New Zealander',
  Nicaraguan: 'Nicaraguan',
  Nigerian: 'Nigerian',
  Nigerien: 'Nigerien',
  Niuean: 'Niuean',
  NorthKorean: 'North Korean',
  Norwegian: 'Norwegian',
  Omani: 'Omani',
  Pakistani: 'Pakistani',
  Palauan: 'Palauan',
  Palestinian: 'Palestinian',
  Panamanian: 'Panamanian',
  PapuaNewGuinean: 'Papua New Guinean',
  Paraguayan: 'Paraguayan',
  Peruvian: 'Peruvian',
  PitcairnIslander: 'Pitcairn Islander',
  Polish: 'Polish',
  Portuguese: 'Portuguese',
  Prydeinig: 'Prydeinig',
  PuertoRican: 'Puerto Rican',
  Qatari: 'Qatari',
  Romanian: 'Romanian',
  Russian: 'Russian',
  Rwandan: 'Rwandan',
  Salvadorean: 'Salvadorean',
  Sammarinese: 'Sammarinese',
  Samoan: 'Samoan',
  SaoTomean: 'Sao Tomean',
  SaudiArabian: 'Saudi Arabian',
  Senegalese: 'Senegalese',
  Serbian: 'Serbian',
  SierraLeonean: 'Sierra Leonean',
  Singaporean: 'Singaporean',
  Slovak: 'Slovak',
  Slovenian: 'Slovenian',
  SolomonIslander: 'Solomon Islander',
  Somali: 'Somali',
  SouthAfrican: 'South African',
  SouthKorean: 'South Korean',
  SouthSudanese: 'South Sudanese',
  Spanish: 'Spanish',
  SriLankan: 'Sri Lankan',
  StHelenian: 'St Helenian',
  StLucian: 'St Lucian',
  Stateless: 'Stateless',
  Sudanese: 'Sudanese',
  Surinamese: 'Surinamese',
  Swazi: 'Swazi',
  Swedish: 'Swedish',
  Swiss: 'Swiss',
  Syrian: 'Syrian',
  Taiwanese: 'Taiwanese',
  Tajik: 'Tajik',
  Tanzanian: 'Tanzanian',
  Thai: 'Thai',
  Togolese: 'Togolese',
  Tongan: 'Tongan',
  Trinidadian: 'Trinidadian',
  Tristanian: 'Tristanian',
  Tunisian: 'Tunisian',
  Turkish: 'Turkish',
  Turkmen: 'Turkmen',
  TurksAndCaicosIslander: 'Turks and Caicos Islander',
  Tuvaluan: 'Tuvaluan',
  Ugandan: 'Ugandan',
  Ukrainian: 'Ukrainian',
  Uruguayan: 'Uruguayan',
  Uzbek: 'Uzbek',
  VaticanCitizen: 'Vatican citizen',
  Venezuelan: 'Venezuelan',
  Vietnamese: 'Vietnamese',
  Vincentian: 'Vincentian',
  Wallisian: 'Wallisian',
  Yemeni: 'Yemeni',
  Zambian: 'Zambian',
  Zimbabwean: 'Zimbabwean',
};

export default nationalities;
