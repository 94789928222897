import axios from 'axios';

export default ({ persons }) => {
  return new Promise((resolve, reject) => {
    Promise.all(
      persons.map((item) => {
        let personData = {
          title: item.title,
          first_name: item.firstName,
          middle_name: item.middleName,
          last_name: item.lastName,
          email: item.email,
          phone_mobile: item.phoneMobile,
          profile_occupier: {
            age: Number(item.age),
            relationship: item.relationship,
            relationship_other: item.relationshipOther,
          },
        };

        personData = Object.entries(personData).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});
        if (item.hasOwnProperty('personId')) {
          return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/persons/${item.personId}`, personData);
        } else {
          return axios.post(`${process.env.VUE_APP_API_ENDPOINT}/persons`, personData);
        }
      }),
    )
      .then((resultData) => {
        resolve(resultData);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};
